<template>
  <div>
    <process :step="1"></process>
    <div class="content pt-4 pb-4">
      <p class="text-center text_green">ご希望の日時を選択してください</p>
      <p style="margin:0 auto; max-width: 700px; padding: 10px; color: #848484">
        以下よりご希望の日時を選択ください。<br>
        現時点では仮予約として承ります。<br>
        後ほど、当店よりご予約時間について連絡を差し上げます。
      </p>
      <el-form v-model="formData" class="el-form--label-left" ref="form">
        <div v-for="(item,index) in formData" :key="index" class="item-option" >
          <p class="name-option">
            {{'ご希望時間 ' + (index+1)}}
          </p>
          <div>
            <el-form-item label-width="0px">
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="col-item-picker">
                <el-form-item class="item-picker">
                  <el-date-picker
                    v-if="dateBooking[index]"
                    class="date-picker icon-calendar-repeater"
                    :picker-options="pickerOptions"
                    type="date"
                    :clearable="false"
                    format="yyyy/MM/dd"
                    value-format="yyyy-MM-dd"
                    @change="chooseDate(item.date, index)"
                    v-model="item.date">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="col-item-picker">
                <el-form-item prop="start_time" class="item-picker">
                  <el-select v-model="item.start_time" placeholder="選択してください" class="date-picker">
                    <el-option
                      v-for="(itemBooking,indexTime) in optionTime" :key="indexTime"
                      :label="itemBooking"
                      :value="itemBooking"
                      :disabled="blockHour(itemBooking,item.listBlockHour)
                    || blockHour(itemBooking,item.listStoreSchedule)
                    || blockHour(itemBooking,blockTime24h[index] ? blockTime24h[index] : [])">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>
          </div>
        </div>
        <div class="text-center">
          <Button customStyle="min-width: 296px" @submit="nextStep()">ご予約に進む<span style="padding-left: 10px"></span></Button>
        </div>
      </el-form>
    </div>
    <!-- dialog error not choose -->
    <el-dialog :visible.sync="dialogError" width="30%" center>
      <div>
        <div class="modal-body content_popup">
          <div class="custom-col el-form-custom text-center" ref="dialogError">
            必須項目に入力してください。
          </div>
        </div>
        <div class="modal-footer text-center">
          <b-button class="btn-back text-center" @click="closeModalCheckChoose()">
            閉じる
          </b-button>
        </div>
      </div>
    </el-dialog>
    <!-- end dialog error not choose -->
  </div>
</template>

<script>
import * as CONSTANTS from "@/core/config/constant";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import ApiService from "@/core/services/api.service";
import {SET_CHOOSE_PROCESS, DATE_BOOKING} from "@/core/services/store/booking.module";
import process from "@/components/layout/components/process";
import Button from "@/components/layout/components/ButtonCommon";

export default {
  name: "Repeater",
  components : {
    process,
    Button
  },
  data() {
    return {
      formData: [],
      dataDefault: {
        listBlockHour: [],
        listStoreSchedule: [],
        date: moment().add(1, 'days').format('YYYY-MM-DD'),
        start_time: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      fromDate: null,
      optionTime: [],
      date: [],
      time: '',
      checkSelect: false,
      IndexSel: [],
      dialogError: false,
      store_id: '',
      listBlockHour: '',
      listStoreSchedule: [],
    };
  },

  computed: {
    ...mapGetters(["dateBooking", "dataProcess", "currentUser", "storeId"]),

    blockTime24h() {
      let blockTime24h = []
      this.formData.forEach((data, index) => {
        if(moment(data.date, 'yyyy-MM-DD').isSame(moment().add('1', 'days').format('yyyy-MM-DD'))) {
          blockTime24h[index] = []
          blockTime24h[index] = [{
            star_hour: '00:15',
            end_hour: moment().format('HH:mm')
          }]
        }
      })
      return blockTime24h
    }
  },

  async created() {
    if (!Array.isArray(this.dataProcess) || this.dataProcess.length === 0) {
      this.$router.push({name: 'combo-list', query: {id: this.$route.query.id}})
    }
    this.optionTime = this.getGroupTime(CONSTANTS.START_TIME, CONSTANTS.END_TIME);
    this.optionTime = this.optionTime.filter(time => {
      return time !== 1260
    })
    this.store_id = this.storeId;
    if (!Array.isArray(this.dateBooking)
      || this.dateBooking.length !== 3
      || !moment(this.dateBooking[0].date)
      || !moment(this.dateBooking[1].date)
      || !moment(this.dateBooking[2].date)
      || moment(this.dateBooking[0].date).isBefore(moment().add(1, 'days').format('YYYY-MM-DD'))
      || moment(this.dateBooking[1].date).isBefore(moment().add(1, 'days').format('YYYY-MM-DD'))
      || moment(this.dateBooking[2].date).isBefore(moment().add(1, 'days').format('YYYY-MM-DD'))
    ) {
      let block = await this.getBlockHour(moment().add(1, 'days').format('YYYY-MM-DD'))
      this.dateBook([
        {date: moment().add(1, 'days').format('YYYY-MM-DD'), start_time: '', listStoreSchedule: block.listStoreSchedule, listBlockHour: block.listBlockHour},
        {date: moment().add(1, 'days').format('YYYY-MM-DD'), start_time: '', listStoreSchedule: block.listStoreSchedule, listBlockHour: block.listBlockHour},
        {date: moment().add(1, 'days').format('YYYY-MM-DD'), start_time: '', listStoreSchedule: block.listStoreSchedule, listBlockHour: block.listBlockHour},
      ]);
    } else {
      const blockHour = await this.getAllBlockHours();
      const date1 = moment(this.dateBooking[0].date).format('YYYY-MM-DD')
      let time1 = this.dateBooking[0].start_time
      const block1 = blockHour[0]
      const date2 = moment(this.dateBooking[1].date).format('YYYY-MM-DD')
      let time2 = this.dateBooking[1].start_time
      const block2 = blockHour[1]
      const date3 = moment(this.dateBooking[2].date).format('YYYY-MM-DD')
      let time3 = this.dateBooking[2].start_time
      const block3 = blockHour[2]

      let blockStoreSchedule1 = []
      if(block1.storeSchedule[0]) {
        if(block1.storeSchedule[0].status === 1) {
          blockStoreSchedule1 = [
            {
              star_hour: '00:15',
              end_hour: block1.storeSchedule[0].work_start_time
            },
            {
              star_hour: block1.storeSchedule[0].work_end_time,
              end_hour: '22:00',
            },
          ]
          if(block1.storeSchedule[0].off_start_time && block1.storeSchedule[0].off_end_time) {
            blockStoreSchedule1.push({
              star_hour: block1.storeSchedule[0].off_start_time,
              end_hour: block1.storeSchedule[0].off_end_time
            })
          }
        }
        else {
          blockStoreSchedule1 = [
            {
              star_hour: '00:15',
              end_hour: '21:00'
            },
          ]
        }
      }
      let blockStoreSchedule2 = []
      if(block2.storeSchedule[0]) {
        if(block2.storeSchedule[0].status === 1) {
          blockStoreSchedule2 = [
            {
              star_hour: '00:15',
              end_hour: block2.storeSchedule[0].work_start_time
            },
            {
              star_hour: block2.storeSchedule[0].work_end_time,
              end_hour: '22:00',
            },
          ]
          if(block2.storeSchedule[0].off_start_time && block2.storeSchedule[0].off_end_time) {
            blockStoreSchedule2.push({
              star_hour: block2.storeSchedule[0].off_start_time,
              end_hour: block2.storeSchedule[0].off_end_time
            })
          }
        }
        else {
          blockStoreSchedule2 = [
            {
              star_hour: '00:15',
              end_hour: '21:00'
            },
          ]
        }
      }

      let blockStoreSchedule3 = []
      if(block3.storeSchedule[0]) {
        if(block3.storeSchedule[0].status === 1) {
          blockStoreSchedule3 = [
            {
              star_hour: '00:15',
              end_hour: block3.storeSchedule[0].work_start_time
            },
            {
              star_hour: block3.storeSchedule[0].work_end_time,
              end_hour: '22:00',
            },
          ]
          if(block3.storeSchedule[0].off_start_time && block3.storeSchedule[0].off_end_time) {
            blockStoreSchedule3.push({
              star_hour: block3.storeSchedule[0].off_start_time,
              end_hour: block3.storeSchedule[0].off_end_time
            })
          }
        }
        else {
          blockStoreSchedule3 = [
            {
              star_hour: '00:15',
              end_hour: '21:00'
            },
          ]
        }
      }
      const blockListBlockHour1 = (block1.blockHours[0]) ? block1.blockHours[0] :[]
      const blockListBlockHour2 = (block2.blockHours[0]) ? block2.blockHours[0] :[]
      const blockListBlockHour3 = (block3.blockHours[0]) ? block3.blockHours[0] :[]

      if(this.blockHour(time1, blockListBlockHour1) ||
        this.blockHour(time1, blockStoreSchedule1) ||
        this.blockTime24h[0]) {
        time1 = ""
      }
      if(this.blockHour(time2, blockStoreSchedule2) ||
        this.blockHour(time2, blockListBlockHour2) ||
        this.blockTime24h[1]) {
        time2 = ""
      }
      if(this.blockHour(time3, blockStoreSchedule3) ||
        this.blockHour(time3, blockListBlockHour3) ||
        this.blockTime24h[2]) {
        time3 = ""
      }

      this.dateBook([
        {date: date1, start_time: time1, listStoreSchedule: blockStoreSchedule1, listBlockHour: blockListBlockHour1},
        {date: date2, start_time: time2, listStoreSchedule: blockStoreSchedule2, listBlockHour: blockListBlockHour2},
        {date: date3, start_time: time3, listStoreSchedule: blockStoreSchedule3, listBlockHour: blockListBlockHour3},
      ]);

    }
    this.formData = this.dateBooking
  },
  methods: {
    ...mapActions([DATE_BOOKING, SET_CHOOSE_PROCESS]),

    async getBlockHour (date) {
      try {
        this.startLoading();
        const {data} = await ApiService.get(`booking/block-hour?store_id=` + this.store_id + '&' + `date=` + date)
        this.endLoading();
        let arrayListBlockHours = []
        let listStoreSchedule = []
        if(data.data.blockHours && data.data.blockHours.length > 0) {
          data.data.blockHours.forEach((value) => {
            value.forEach((val) => {
              arrayListBlockHours.push(val);
            });
          });
        }
        if(data.data.storeSchedule && data.data.storeSchedule.length > 0) {
          if (data.data.storeSchedule[0].status === 1) {
            listStoreSchedule = [{
              star_hour: '00:15',
              end_hour: data.data.storeSchedule[0].work_start_time
            },
              {
                star_hour: data.data.storeSchedule[0].work_end_time,
                end_hour: '23:00'
              }]
            if(data.data.storeSchedule[0].off_start_time && data.data.storeSchedule[0].off_end_time) {
              listStoreSchedule.push(
                {
                  star_hour: data.data.storeSchedule[0].off_start_time,
                  end_hour: data.data.storeSchedule[0].off_end_time
                }
              )
            }
          }
          else {
            listStoreSchedule = [{
              star_hour: '00:15',
              end_hour: '23:00'
            }]
          }
        }
        return {
          listStoreSchedule: listStoreSchedule,
          listBlockHour: arrayListBlockHours
        }
      } catch (e) {
          this.endLoading();
        this.notifyError(e.message)
        return {
          listStoreSchedule: [],
          listBlockHour: []
        }
      }
    },
    async chooseDate(date, index) {
      let block = await this.getBlockHour(date)
      this.formData[index].listStoreSchedule = block.listStoreSchedule
      this.formData[index].listBlockHour = block.listBlockHour
      this.formData[index].start_time = ''
    },

    async getAllBlockHours() {
      this.startLoading()
      const {data} = await ApiService.get('booking/all-block-hour?store_id=' + this.store_id + '&date[0]='
        + moment(this.dateBooking[0].date).format('YYYY-MM-DD')
        + '&date[1]=' + moment(this.dateBooking[1].date).format('YYYY-MM-DD')
        + '&date[2]=' + moment(this.dateBooking[2].date).format('YYYY-MM-DD'))
      this.endLoading()
      return data.data;
    },

    closeModalCheckChoose() {
      this.dialogError = false;
    },

    nextStep() {
      let checkRedirect = false
      let checkDuplicate = false
      this.formData.forEach((value) => {
        if (value.date && value.start_time) {
          checkRedirect = true
        } 
        let duplicate = this.formData.filter((data) => {
          return data.date && data.start_time && value.date === data.date && value.start_time === data.start_time
        });
        if (duplicate.length >= 2) {
          checkDuplicate = true
        }
      });
      if (checkRedirect) {
        if (checkDuplicate) {
          this.notifyError('ご要望時間が重複できませんですので、再度ご確認お願い致します。')
        } else {
          this.dateBook(this.formData);
          this.$router.push({name: 'info-user', query: {id: this.$route.query.id}});
        }
      } else {
        this.dialogError = true;
      }
    },
  }
}
</script>

<style scoped>
.content {
  font-weight: bold;
}
button.el-button.btn-custom.btn-edit.el-button--default {
  background-color: #85ce61;
  color: white;
  width: 200px;
}

.text_green {
  font-size: 30px;
}

.name-option {
  font-weight: bold;
  font-size: 18px;
  color: #848484;
}

.hide-select {
  display: none;
}

.select {
  display: flex;
}

button.btn.btn-back.text-center.btn-secondary {
  margin: auto;
}
@media only screen and (max-width: 770px) and (min-width: 350px){
  .el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date {
    width: 100%;
  }
  .el-select {
    width: 100%;
  }
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.item-option {
  margin: 0 auto;
  padding: 10px;
  max-width: 700px;
}
.item-picker {
  width: 98%;
}
.date-picker {
  width: 100% !important;
}
@media only screen and (max-width: 290px) {
  .col-item-picker {
    width: 100%;
    padding: 10px;
  }
}
</style>
