<template>
  <div>
    <header align="center" class="content-header">
      決済情報
    </header>
    <div class="common-box">
      <div class="box payment-body">
        <table class="table table-borderless table-payment">
          <thead>
          <tr>
            <th>担当者名</th>
            <th>{{data.responsible_name}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>日付</th>
            <th>{{data.date ? moment(data.date).format('YYYY/MM/DD') : ''}}</th>
          </tr>
          <tr>
            <th>支払い方法</th>
            <th>{{data.type}}</th>
          </tr>
          <tr>
            <th>全額</th>
            <th>{{data.total_money}} 円</th>
          </tr>
          <tr v-if="data.coupon">
            <th>クーポンコード</th>
            <th>{{data.coupon}}</th>
          </tr>
          <tr  v-if="data.coupon">
            <th>割引価</th>
            <th>- {{data.discount}} pt</th>
          </tr>
          <tr>
            <th colspan="2">
              <div class="dashedSpace"></div>
            </th>
          </tr>
          <tr>
            <th>税金 (%)</th>
            <th>{{data.tax_fee}} %</th>
          </tr>
          <tr>
            <th>合計（税込）</th>
            <th class="pt-3">{{data.total_money_after_tax}}  円</th>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="button-box payment-body d-flex justify-content-center btn-payment-info">
        <Button @submit="$router.push({name: 'history-booking'})" content="戻る" customStyle="width:90%;"></Button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .dashed {
    border: none;
    height: 1px;
    background: #000;
    background: repeating-linear-gradient(90deg,#000,#000 6px,transparent 6px,transparent 12px);
  }
  .box {
    font-size: 0.8em;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    box-shadow: rgba(182, 153, 153, 0.5) 4px 4px 4px;
    background-color: white;
  }
  .button-box{
    padding-bottom: 30px;
  }
  p {
    margin: 8px 0px;
  }
  header {
    padding: 12px;
    background-color: #191970;
    color: white;
  }
  .content-header {
    font-size: 19px;
    font-weight: 500;
  }
  .content-title {
    font-size: 15px;
    font-weight: 600;
  }
  .content-detail{
    font-size: 13px;
    font-weight: 600;
  }
  .error {
    color: red;
    font-weight: bold;
    text-align: center;
    margin: 8px;
  }
  .dashedSpace {
    height:6px;
    margin-top:3px;
    background-image:linear-gradient(to right, #C6C6C6 0%, #C6C6C6 50%, transparent 50%);
    background-size:25px 1px;
    background-repeat:repeat-x;
  }
  .table-payment tr th{
    width: 60%;
    font-size: 15px;
    line-height: 29px;
    padding: 0.5rem 0.1rem;
  }
  .table-payment tr th:last-child{
    width: 40%;
    text-align: end;
    font-size: 13px;
  }
  .payment-body {
    padding: 22px;
  }
  @media only screen and (max-width: 600px) {
    .table-payment tr th{
      font-size: 11px;
      line-height: 22px;
    }
    .table-payment tr th:last-child{
      font-size: 12px;
    }
  }
</style>

<script>
import {PAYMENT} from "@/core/services/store/payment.module";
import Button from "@/components/layout/components/ButtonCommon";
import moment from "moment";

export default {
  name: 'payment',
  components : {
    Button
  },
  props: {},
  data () {
    return {
       data: '',
       error: '',
       moment: moment
    };
  },

  created () {
    this.paymentDetail();
  },

  methods: {
    async paymentDetail() {
      try {
        const id = this.$route.params.paymentId;
        const { data } = await PAYMENT.detail(id);
        if (data.success) {
          this.data = data.data
          if (this.data.type == 1) {
            this.data.type = 'Paypay'
          }
          if (this.data.type == 2) {
            this.data.type = 'Credit Card'
          }
          if (this.data.type == 3) {
            this.data.type = '銀行振込'
          }
          this.data.date = this.data.date.slice(0, 10)
          this.data.minus_point = this.data.current_point - this.data.use_point
          this.data.plus_point = this.data.total_money * this.data.ratio / 100
          for (let key in this.data) {
            if (typeof(this.data[key]) === "number") {
              this.data[key] = this.changeNumber(this.data[key])
            }
          }
        }
      } catch (error) {
        this.notifyError(error)
        // this.$router.push({name: "not-found"})
        this.error = error;
      }
    }
  }
}
</script>
