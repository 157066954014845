<template>
  <div class="content-pdf">
    <iframe
      v-if="$route.query.path"
      :src="$route.query.path"
      frameBorder="0"
      scrolling="auto"
      height="100%"
      width="100%"
    ></iframe>
    <div class="justify-content-center d-flex">
      <Button customStyle="width:50%" @submit="backToPageView" customClassName="border-none-and-background-aqua">戻る</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/layout/components/ButtonCommon";

export default {
  name: 'welcome',
  components : {
    Button
  },
  methods: {
    
  }
}
</script>

<style scoped>
.content-pdf {
  height: 100vh;
  width: 100%;
}
</style>
