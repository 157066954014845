<template>
  <div class="container pt-3">
      <span class="font-weight-bold">{{msg}}</span>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import ApiService from "@/core/services/api.service";


export default {
  name: 'Verify',
  data(){
    return {
      msg: null,
    }
  },
  computed:{
    ...mapGetters([
      'currentCode',
      'isAuthenticated'
    ]),
  },
  async created() {
    this.checkNewUser()
  },
  methods: {
    checkNewUser(){
      this.startLoading()
      const token = this.$route.query.token
      ApiService.post('check-new?token=' + token)
        .then(({data}) => {
          if (this.$route.query.type) {
            this.$router.push({ name: 'welcome', query: {type: this.$route.query.type} })
          } else {
            this.$router.push({ name: 'welcome' })
          }
        this.endLoading();
      })
        .catch((error) => {
          this.endLoading();
          if (error.response.status === 400) {
            this.msg = error.response.data.message;
          } else {
            this.notifyError(error.message)
          }

        });
    }

  }
}
</script>

<style scoped>
.hello {
  display: table;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 0px 0px 15px 0px;
  padding: 10px 10px 10px 15px;
  color: #fff;
  background-color: #00b900;
}

.hello a svg {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 15px 0px 0px;
}

.hello p {
  display: inline-block;
  margin: 0px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: middle;
  max-width: 210px;
}
.login {
  display: flex;
  height: 500px;
  justify-content: center;
  /* justify-items: center; */
  align-items: center;
}
</style>
