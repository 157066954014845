import tree from "element-ui/packages/table/src/store/tree";

export const START_LOADING = "startLoading";
export const END_LOADING = "endLoading";
export const SET_IMAGE_DEFAULT = "setImageDefault";
export const SET_IMAGE_DEFAULT_AVATAR = "setImageDefaultAvatar";
export const OPEN_DIALOG = "openDialog";
export const CLOSE_DIALOG = "closeDialog";
export const SET_CONTENT = "setContent";
export const SET_TYPE = "setType";
export const SET_BTN_OK = "setBtnOK";
export const SET_TOKEN_MESSAGE = "setTokenMessage";
export const SET_VALUE_CHECK_SESSION_CC = "setValueCheckSessionCc";

export default {
    state: {
        loading: false,
        imageDefault: '',
        imageDefaultAvatar: '',
        dialog: false,
        content: '',
        btnOK: 'はい',
        type: 'error',
        tokenMessage: '',
        haveSessionCc: false,
    },
    getters: {
        getTokenMessage: state => {
            return state.tokenMessage;
        },
        checkSessionCc: state => {
            return state.haveSessionCc;
        }
    },
    actions: {
        /**
         *
         * Start loading
         * @param state
         */
        [START_LOADING](state) {
            state.commit(START_LOADING);
        },

        /**
         * End loading
         * @param state
         */
        [END_LOADING](state) {
            state.commit(END_LOADING);
        },
        [SET_IMAGE_DEFAULT](state, payload) {
            state.commit(SET_IMAGE_DEFAULT, payload);
        },
        [SET_IMAGE_DEFAULT_AVATAR](state, payload) {
            state.commit(SET_IMAGE_DEFAULT_AVATAR, payload);
        },
        [OPEN_DIALOG](state, payload = true) {
            state.commit(OPEN_DIALOG, payload);
        },
        [CLOSE_DIALOG](state, payload) {
            state.commit(CLOSE_DIALOG, payload);
        },
        [SET_CONTENT](state, payload) {
            state.commit(SET_CONTENT, payload);
        },
        [SET_TYPE](state, payload) {
            state.commit(SET_TYPE, payload);
        },
        [SET_BTN_OK](state, payload) {
            state.commit(SET_BTN_OK, payload);
        },
        [SET_TOKEN_MESSAGE](state, payload) {
            state.commit(SET_TOKEN_MESSAGE, payload);
        },
        [SET_VALUE_CHECK_SESSION_CC](state, payload) {
            state.commit(SET_VALUE_CHECK_SESSION_CC, payload);
        },
    },
    mutations: {
        [START_LOADING](state) {
            state.loading = true
        },
        [END_LOADING](state) {
            state.loading = false
        },
        [SET_IMAGE_DEFAULT](state, payload) {
            state.imageDefault = payload
        },
        [SET_IMAGE_DEFAULT_AVATAR](state, payload) {
            state.imageDefaultAvatar = payload
        },
        [OPEN_DIALOG](state, payload = true) {
            state.dialog = payload
        },
        [CLOSE_DIALOG](state) {
            state.dialog = false
        },
        [SET_CONTENT](state, payload) {
            state.content = payload
        },
        [SET_TYPE](state, payload) {
            state.type = payload
        },
        [SET_BTN_OK](state, payload) {
            if (payload) {
                state.btnOK = payload
            }
            state.btnOK = 'OK'
        },
        [SET_TOKEN_MESSAGE](state, payload) {
            state.tokenMessage = payload
        },
        [SET_VALUE_CHECK_SESSION_CC](state) {
            state.haveSessionCc = true
        },
    },
}
