<template>
    <div v-loading="loading" class="responsive">
      <el-col :span="24" class="background">
        <component v-bind:is="'layout-ansena'"></component>
        <notification />
      </el-col>
    </div>
</template>
<script>
import LayoutAnsena from "./components/layout-ansena/Layout";
import {mapState} from "vuex";
import Notification from "@/components/layout/components/Notification";

export default {
	name: 'App',
	components: {
    Notification,
    "layout-ansena":LayoutAnsena
	},
  computed: {
    ...mapState({
      loading: state => state.loading.loading
    }),
  },
}
</script>

<style>
.responsive {
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
}
.background {
  background-color: white;
}
</style>
