<template>
  <div class="pt-4 registe-email">
    <div class="container login" v-if="currentUser.email">
      <p class="font-weight-bold">メールアドレスが認証されましたので、再度認証できません。</p>
      <p class="font-weight-bold">ご迷惑をお掛けして、申し訳ございません。</p>
      <div class="justify-content-center d-flex">
        <Button customStyle="width:50%" @submit="backToLineApp" customClassName="border-none-and-background-aqua">LINEに戻る</Button>
      </div>
    </div>
    <div v-else>
      <div v-if="!sendEmailSuccessfully" class="justify-content-center">
        <p class="text-center text_green font-weight-bold">メールアドレス認証</p>
        <div style="margin-left: 30px;">
          <p class="font-weight-bold">メールアドレスのご入力をお願い致します。</p>
        </div>
        <el-form ref="form" :model="form" style="margin: 30px" @submit.prevent.native="openDialog" class="justify-content-center">
          <el-form-item
            label="メールアドレス"
            prop="email"
            class="font-weight-bold"
          >
            <el-input v-model="form.email" placeholder="入力してください"></el-input>
          </el-form-item>
          <el-form-item class="dialog-footer pt-3" style="text-align: center">
            <Button @submit="openDialog" customStyle="min-width: 296px" style="font-size: 20px" class="text-white border-0 font-weight-bold">次へ</Button>
          </el-form-item>
        </el-form>
        <Dialog :dialog="dialogVisible" @closeDialog="dialogVisible=false" @submit="sendmail"
                :close-on-click-modal="false">
          <div slot="content" class=" text-center">
            <h4>メール送信確認</h4>
            <span>メールアドレスを送信してもよろしいですか？</span>
          </div>
        </Dialog>

        <Dialog :dialog="errorDialog" @closeDialog="errorDialog=false" @submit="errorDialog=false"
                :close-on-click-modal="false">
          <div slot="content" class="justify-content-center text-center">
            <span>メール送信が出来なかったので、再度ご入力してください。</span>
          </div>
        </Dialog>
      </div>
      <div v-else>
        <div class="main-container pt-5">
          <div class="check-container">
            <div class="check-background">
              <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="check-shadow"></div>
          </div>
          <br>
          <span class="font-weight-bold p-1 " style="font-size: 13px">メールアドレスに認証リンクを送信しました。<br><br>
        記載されたリンクをクリックして手続きを進めてください。<br><br>
        メールが届かない場合、迷惑メールなどをご確認ください。</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SENDEMAIL} from "../../core/services/store/sendEmail.module";
import Dialog from "@/components/layout/components/Dialog";
import Button from "@/components/layout/components/ButtonCommon";
import {mapGetters} from "vuex";
import {LOGOUT} from "@/core/services/store/auth.module";

export default {
  name: 'register',
  components: {Dialog, Button},
  data () {
    return {
      form: {
        email:null,
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      },
      dialogVisible: false,
      sendEmailSuccessfully: false,
      errorDialog: false
    };
  },
  computed:{
    ...mapGetters([
      'currentUser',
      'isAuthenticated'
    ]),
  },

  methods : {
    openDialog(){
      this.$refs.form.clearValidate(this.form)
      if(this.form.email == null || this.form.email == '')
      {
        let obj = {email:["必須項目に入力してください。"]};
        this.showErrorMessage( obj, 'form');
      }
      else if(!this.form.reg.test(this.form.email))
      {
        let obj = {email:["無効なメールアドレス。"]};
        this.showErrorMessage( obj, 'form');
      }
      else{
        this.dialogVisible= true;
      }
    },
    async sendmail()
    {
      this.dialogVisible = false;
      let type = 'new'
      if (this.$route.query.type) {
        type = this.$route.query.type
      }
      this.startLoading();
      const emailData = {
        'email': this.form.email,
        'type': type,
        'line_id': this.$store.state.auth.user.line_id
      }
      try {
        await SENDEMAIL.sendEmail(emailData);
        this.sendEmailSuccessfully = true
        this.$store.dispatch(LOGOUT)
        this.endLoading();
      } catch (e) {
        this.endLoading();
        if (e.response.status === 422) {
          this.showErrorMessage(e.response.data.message, "form");
        } else {
          this.errorDialog = true;
          this.notifyError(e.response.statusText)
        }
      }
    },
  }

}

</script>

<style scoped>
.choose-type-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh;
}
.text_green {
  font-size: 30px;
}
.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.check-container {
  width: 6.25rem;
  height: 7.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}
.check-container .check-background {
  width: 100%;
  height: calc(100% - 1.25rem);
  background: linear-gradient(to bottom right, #5de593, #41d67c);
  box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  transform: scale(0.84);
  border-radius: 50%;
  animation: animateContainer 0.75s ease-out forwards 0.75s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.check-container .check-background svg {
  width: 65%;
  transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: animateCheck 0.35s forwards 1.25s ease-out;
}
.check-container .check-shadow {
  bottom: calc(-15% - 5px);
  left: 0;
  border-radius: 50%;
  background: radial-gradient(closest-side, #49da83, transparent);
  animation: animateShadow 0.75s ease-out forwards 0.75s;
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  43.75% {
    transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  62.5% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }
  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}
@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }
  25% {
    opacity: 0.25;
  }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}
</style>

