<template>
  <div class="common-box">
      <div class="campaign-body" style="background-color: white">
          <h3>{{campainDetail.title}}</h3>
          <div  class="mb-3 campaign-content" v-html="campainDetail.content"></div>
          <Button v-if="checkCurrenDevice" @submit="backToLineApp" class="btn-campaign-detail" content="戻る"></Button>
      </div>
    <el-dialog title="Outer Dialog" :visible.sync="dialogVisible" :close-on-click-modal="false"
    >
      <span>{{msg}}</span>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">OK</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {DETAIL_CAMPAIN} from "../../core/services/store/detailCampain.module";
import Button from "@/components/layout/components/ButtonCommon";

export default {
  name: 'campaign',
  components : {
    Button
  },
  props: {},
  data () {
    return {
      msg: '',
      dialogVisible: false,
      campainDetail:{
        title: '',
        content: ''
      }
    };
  },
  computed: {
    checkCurrenDevice() {
      return this.checkDevice();
    }
  },
  created () {
    this.detailCampain();
  },

  methods: {
    async detailCampain () {
        this.startLoading();
        try {
            const id = this.$route.params.campainId;
            const { data } = await DETAIL_CAMPAIN.detail(id);
            this.campainDetail = data.data;
        } catch (e) {
            if (e.response.status === 500) {
              this.msg = e.response.data.data
              this.dialogVisible = true
            } else {
              this.notifyError(e.message)
            }
        } finally {
            this.endLoading();
        }
     }
  }
}
</script>

<style scoped>
.campaign-body {
  background-color: white;
  padding: 35px 20px;
}
.campaign-body >  h3 {
  color: #72C9CC;
}
.campaign-content {
  overflow: auto;
}
</style>
