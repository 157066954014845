<template>
  <div class="">
    <section class="showcase position-relative">
      <div class="position-absolute login-footer">
        <div class="mb-4 btn-login">
          <div class="line-body" @click="loginWithLine" style="cursor: pointer">
            <a>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30.666" viewBox="0 0 32 30.666">
                <path id="line"
                      d="M16,.5C7.18.5,0,6.364,0,13.571c0,6.455,5.693,11.863,13.38,12.891.521.111,1.231.347,1.411.792a3.3,3.3,0,0,1,.051,1.449l-.219,1.368c-.06.4-.32,1.591,1.4.865s9.221-5.472,12.581-9.359A11.6,11.6,0,0,0,32,13.571C32,6.364,24.82.5,16,.5ZM9.737,17.856H6.556a.848.848,0,0,1-.84-.844v-6.4a.84.84,0,1,1,1.68,0v5.556H9.737a.845.845,0,0,1,0,1.689Zm3.288-.844a.839.839,0,1,1-1.677,0v-6.4a.839.839,0,1,1,1.677,0Zm7.655,0a.845.845,0,0,1-.576.8.861.861,0,0,1-.265.041.823.823,0,0,1-.68-.336L15.9,13.067v3.944a.838.838,0,1,1-1.676,0v-6.4a.841.841,0,0,1,.573-.8.776.776,0,0,1,.259-.044.861.861,0,0,1,.66.341L19,14.577V10.611a.84.84,0,1,1,1.68,0Zm5.14-4.047a.846.846,0,0,1,0,1.692H23.48v1.509h2.34a.845.845,0,0,1,0,1.689H22.639a.844.844,0,0,1-.836-.844v-6.4a.845.845,0,0,1,.84-.845h3.181a.845.845,0,0,1,0,1.691H23.48v1.509Z"
                      transform="translate(0 -0.5)" fill="#ffffff"/>
              </svg>
              <p>ぐり〜んりぷるにログインする</p>
            </a>
          </div>
        </div>
        <div class="privacy">
          <div>
            <div class="sub-footer">
              <p>Lineログインは上記のリンクから行うことができます。</p>
              <p>提供：株式会社あんせな</p>
            </div>
            <div>
              <span @click="$router.push({ name: 'term' })" class="term-policy">利用規約</span>
              <span class="sub-footer"> と </span>
              <span  @click="$router.push({ name: 'privacy' })" class="term-policy">プライバシーポリシー</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import env from "../core/config/env";
import queryString from 'querystring';
import OAuthResult from "../core/config/OAuthResult";
import OAuthRequest from "../core/config/OAuthRequest";
import axios from "axios";
import linq from "linq"
import {LOGIN_LINE, LOGOUT} from "@/core/services/store/auth.module";

export default {
    name: 'Login',
    props: {
        msg: String
    },
    async created() {
        this.$store.dispatch(LOGOUT, token)
        const { code } = queryString.parse(window.location.search.replace('?', ''))
        if(!code) return
        const result = await this.getToken(code)

        let token = {
            token: result.idToken,
            driver: "line"
        }
        this.startLoading();
        this.$store
            .dispatch(LOGIN_LINE, token)
            .then(data => {
                this.endLoading();
                if (data.data.original.code === 200) {
                    const redirect = localStorage.getItem("redirect")
                    if (redirect) {
                        localStorage.removeItem("redirect")
                        this.$router.push({path: redirect})
                    } else {
                        this.$router.push({name: "choose-type-user"})
                    }
                }
                if (data.data.original.code === 422) {
                    this.error = data.data.data
                }
            }).catch(err => {
            this.endLoading();
            this.notifyError(err)
        });
    },
    methods: {
        async loginWithLine() {
            this.startLoading();
            const url = env.LOGIN_LINE.authorize_url
            let params = {
                response_type: 'code',
                client_id: env.LOGIN_LINE.client_id,
                redirect_uri: env.LOGIN_LINE.redirect_url,
                state: Math.random().toString(32).substring(2),
                scope: 'openid profile',
            }
            window.location.href = `${url}?${queryString.stringify(params)}`
            const { code } = queryString.parse(window.location.search.replace('?', ''))
            if(!code) return
            await this.getToken(code)
        },
        async getToken(code) {
            const result = new OAuthRequest({
                code: code,
                clientId: env.LOGIN_LINE.client_id,
                clientSecret: env.LOGIN_LINE.clientSecret,
                redirectUri: env.LOGIN_LINE.redirect_url
            })
            const params = new URLSearchParams()
            linq.from(result.getPostable()).select(x => params.append(x.key, x.value)).toArray()
            const { data } = await axios.post(env.LOGIN_LINE.token_url, params)
            return new OAuthResult(data)
        },
    }
}
</script>

<style scoped>
.line-body {
   height: 100%;
   width: 85%;
    background-color: #00b900;
    margin: 0 auto;
  line-height: 67px;
}

.line-body a svg {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 30px 0px 0px;
}

.line-body p {
    display: inline-block;
    margin: 0px;
  font-size: 21px;
    font-family: Arial, Helvetica, sans-serif;
    vertical-align: middle;
  font-weight: 500;
}
.login {
    display: flex;
    height: 500px;
    justify-content: center;
    align-items: center;
}
.no-margin{
  margin: 0px;
}
.transparent {
  background-color: transparent;
}
.absolute-full-width {
  position: absolute;
  width: 50%;
  height: 50%;
}
.showcase {
  overflow: hidden;
  color: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  text-align: center;
  margin: auto;
  background: url('../../public/img/backgroundImage.png');
  background-size: cover;
  background-position: center;
}
.showcase .login-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.login-footer {
   bottom: 0;
   height: 220px;
   width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.privacy {
  height: 130px;
  background-color: #333A4A;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.btn-login {
  height: 70px;
}
.sub-footer {
  color: #535A6A;
  font-size: 16px;
  font-weight: 600;
}
.term-policy {
  color: #7C8393;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
@media only screen and (max-width: 320px) {
  .line-body p {
    font-size: 20px;
  }
  .login-footer {
    height: 190px;
  }
  .btn-login {
    height: 60px;
  }
  .line-body{
    line-height: 58px;
  }
}
</style>
