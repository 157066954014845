<template>
  <button type="button" :class="customClassName" :style="customStyle" :disabled="checkMultiClick" class="btn-common-submit" @click="$emit('submit')">
    <slot><span>{{ content }}</span></slot>
  </button>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    content: {
      type: String,
      default: 'リセット'
    },
    checkMultiClick: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: String,
      default: 'width:100%'
    },
    customClassName: {
      type: String,
      default: 'border-none-and-background-aqua'
    }
  }
};
</script>

<style scoped>
.btn-common-submit {
  border-radius: unset;
  padding: 12px 20px;
  border:none;
}
.border-aqua-and-background-white , .border-aqua-and-background-white:hover{
  border: 3px solid #191970;
  background-color: white;
  color: #191970;
}
.border-none-and-background-orange {
  background-color: #ff8c00;
  color: white;
}
.border-none-and-background-aqua{
  background-color: #191970;
  color: white;
}
.bg-pendding {
  background-color: #ff8c00;
  color: white;
}
.bg-approval {
  background-color: #191970;
  color: white;
}
.bg-reject {
  background-color: gray;
  color: white;
}
</style>
