// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './core/services/store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
 import './assets/sass/style.css';
import './assets/sass/style-ansena.css';

import lang from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css'
import {baseMixin} from "./mixin";

Vue.use(VueMaterial)
Vue.use(VueAxios, axios)
/*Vue.provide('axios', Vue.config.globalProperties.axios)  // provide 'axios'*/

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {LOGOUT, VERIFY_AUTH} from "@/core/services/store/auth.module";
import {CLOSE_DIALOG} from "@/core/services/store/loading.module";

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.mixin(baseMixin)

locale.use(lang)
Vue.use(ElementUI)

Vue.config.productionTip = false

axios.interceptors.response.use(null, function (error) {
  if (!error.response) {
    return Promise.reject(error);
  }
  if (error.response.status === 401) {
    store.dispatch(LOGOUT)
    store.dispatch(CLOSE_DIALOG)
    error.response.message = 'セクションが切れましたので、再度ログインしてください。'
    router.push({name: 'login'})
  }

  return Promise.reject(error);
});

router.beforeEach((to, from, next) => {
  if (to.query && to.query.redirect) {
    localStorage.setItem('redirect', to.query.redirect)
  }
  // set titel page
  const pageTitle = to.matched.find((item) => item.meta.title);
  if (pageTitle) window.document.title = pageTitle.meta.title;

  if (to.name !== 'login' && to.name !== 'verify') {
    Promise.all([store.dispatch(VERIFY_AUTH, {to:to, isAuthenticated: store.state.auth.isAuthenticated})]).then(next);
  } else {
    Promise.all([store.dispatch(LOGOUT)]).then(next);
  }
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})


