<template xmlns="http://www.w3.org/1999/html">
  <div class="container login">
    <p class="font-weight-bold">アカウント設定手続きをいただきありがとうございました。</p>
    <p class="font-weight-bold">マイページより、プロフィール設定をお願い致します。</p>
    <br>
    <div class="justify-content-center d-flex">
      <Button customStyle="width:50%" @submit="backToLineApp" customClassName="border-none-and-background-aqua">LINEに戻る</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/layout/components/ButtonCommon";

export default {
  name: 'welcome',
  components : {
    Button
  },
}
</script>
<style>
@media only screen and (max-width: 667px) {
  .font-weight-bold {
    font-size: 13px;
  }  
}
@media only screen and (max-width: 400px) {
  .font-weight-bold {
    font-size: 11px;
  }  
}
</style>
<style scoped>
.container {
  padding: 30px;
}
</style>
