<template>
  <div>
    <div class="">
      <header align="center" class="content-header">
        予約履歴
      </header>
      <div class="text-center header-calenda">
        <a href="javascript:void(0)" @click="preMonth()"> <i class="el-icon-arrow-left selet-date"></i> </a>
        <span class="year selet-date"> {{ year }} </span>
        <span class="selet-date"> 年 </span>
        <span class="month selet-date"> {{ month }} 月</span>
        <a href="javascript:void(0)" @click="nextMonth()"><i class="el-icon-arrow-right selet-date"></i></a>
      </div>
      <div class="common-box">
        <div v-for="(item, index) in listBooking" :key="item.id">
          <span class="deatil-date">{{ moment(index).format('YYYY/MM/DD') }}</span>
          <div v-for="item2 in item" :key="item2.id">
            <div class="card mb-4 w&#45;&#45;100" :class="item2.payment || item2.status == 3 ? 'bg-gainsboro' : ''">
              <div class="card-body">
                <h5 class="card-title">{{ item2.name }}</h5>
              </div>
              <div class="d-flex justify-content-center mb-3">
                <img class="img-body-common" :src="item2.image" alt="Card image cap">
              </div>
              <div class="card-body">
                <div class="row" v-if="item2.start_date">
                  <div class="col-4 col-md-2 sub-card-body text-end"> <span class="detail-item"> ご予約日時:</span></div>
                  <div class="col-8 col-md-10 sub-card-body">
                    <p>
                      <span class="pr--3 detail-item">{{ moment(item2.start_date).format('YYYY年MM月DD日')  }}</span>
                      <span class="detail-item">{{ moment(item2.start_date+' '+item2.start_time).format('HH : mm')}}</span>
                    </p>
                  </div>
                </div>
                <div v-else class="row">
                  <div class="col-4 col-md-2 sub-card-body text-end"> <span class="detail-item"> ご予約日時 :</span></div>
                  <div  class="col-8 col-md-10 sub-card-body">
                    <p v-for="detail in item2.detail" :key="detail.id">
                      <span  class="pr--3 detail-item">{{ moment(detail.date).format('YYYY年MM月DD日')  }}</span>
                      <span class="detail-item">{{ moment(detail.date+' '+detail.start_time).format('HH : mm')}}</span>
                    </p>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-4 col-md-2 sub-card-body text-end"> <span class="detail-item">コメント:</span></div>
                  <div class="col-8 col-md-10 sub-card-body">
                    <span class="detail-item" style="white-space:pre-line">{{ item2.customer_comment }}</span>
                  </div>
                </div>
                <div class="btn-history-booking">
                <div class="custom-btn-booking-detail d-flex justify-content-center mb-3" v-if="!item2.payment && item2.status !== 3">
<!--                  rebooking(item2)-->
                  <!-- <Button customClassName="border-aqua-and-background-white" @submit="showPoopupBoooking(item2, true)" content="予約編集"></Button>
                  <div style="width: 30px"></div> -->
                  <Button customClassName="border-none-and-background-aqua" content="予約キャンセル" @submit="showPoopupBoooking(item2)"></Button>
                </div>
                <div v-else-if="item2.payment && item2.status == 2" class="d-flex justify-content-center mb-3 custom-btn-booking-detail">
                  <Button customStyle="width:48%" customClassName="border-none-and-background-aqua" content="決済情報" @submit="$router.push({name: 'payment', params: { paymentId: item2.payment.id }})"></Button>
                </div>
                <Button class="btn-status-booking" v-if="item2.status == 1" customClassName="bg-pendding" content="店舗確認中"></Button>
                <Button class="btn-status-booking" v-else-if="item2.status == 2" customClassName="bg-approval" content="予約確定"></Button>
                <Button class="btn-status-booking" v-else-if="item2.status == 3" customClassName="bg-reject" content="キャンセル済"></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="checkCurrenDevice" class="d-flex justify-content-center btn-footer-booking-detail">
          <Button @submit="backToLineApp" customStyle="width:95%" content="戻る"></Button>
        </div>
      </div>
    </div>
    <!-- dialog cancel popup -->
    <el-dialog class="dialog-cancle-booking" :visible.sync="dialogCancel" width="30%" center>
      <div>
        <div class="modal-body content_popup">
          <div class="custom-col el-form-custom text-center mb-4 header-cancle-booking" ref="dialogError">
            キャンセル理由
          </div>

          <div class="custom-col el-form-custom text-center mb-4" ref="dialogError">
            <Button @submit="reasonActive = 1;confirmCancelBooking(1)" :class="reasonActive == 1 ? 'btn-cancel-booking-active' :'btn-cancel-booking'" content="体調不良・個人的理由"></Button>
          </div>
          <div class="custom-col el-form-custom text-center mb-4" ref="dialogError">
            <Button @submit="reasonActive = 2;confirmCancelBooking(2)" :class="reasonActive == 2 ? 'btn-cancel-booking-active' :'btn-cancel-booking'" content="予約間違い・変更"></Button>
          </div>
          <div class="custom-col el-form-custom text-center mb-4" ref="dialogError">
            <Button @submit="reasonActive = 3;confirmCancelBooking(3)" :class="reasonActive == 3 ? 'btn-cancel-booking-active' :'btn-cancel-booking'" content="お店変更"></Button>
          </div>
          <div class="custom-col el-form-custom text-center mb-4" ref="dialogError">
            <Button @submit="reasonActive = 4;confirmCancelBooking(4)" :class="reasonActive == 4 ? 'btn-cancel-booking-active' :'btn-cancel-booking'" content="旅程変更"></Button>
          </div>
          <div class="custom-col el-form-custom text-center mb-4" ref="dialogError">
            <Button @submit="reasonActive = 5;confirmCancelBooking(5)" :class="reasonActive == 5 ? 'btn-cancel-booking-active' :'btn-cancel-booking'" content="他のサイトがお得"></Button>
          </div>
          <div class="custom-col el-form-custom text-center mb-4" ref="dialogError">
            <Button @submit="reasonActive = 6;confirmCancelBooking(6)" :class="reasonActive == 6 ? 'btn-cancel-booking-active' :'btn-cancel-booking'" content="天候・交通事情"></Button>
          </div>
          <div class="custom-col el-form-custom text-center mb-4" ref="dialogError">
            <Button @submit="reasonActive = 7;confirmCancelBooking(7)" :class="reasonActive == 7 ? 'btn-cancel-booking-active' :'btn-cancel-booking'" content="その他"></Button>
          </div>

        </div>
      </div>
    </el-dialog>
    <el-dialog class="dialog-cancle-booking dialog-contact-booking" :visible.sync="contactPoopup" width="25%" center>
      <div>
        <div class="modal-body content_popup">
          <div class="custom-col el-form-custom text-center dialog-contact-content contact-content" ref="dialogError">
            当日に予約を変更したり､
          </div>
          <div class="custom-col el-form-custom text-center dialog-contact-content contact-content" ref="dialogError">
            キャンセルしたりする場合は、
          </div>
          <div class="custom-col el-form-custom text-center dialog-contact-content contact-content" ref="dialogError">
            当店のLINEにてご連絡をお願いします
          </div>
        </div>
        <div class=" text-center btn-dialog-contact" style="justify-content: center">
          <Button customStyle="width:100%;background-color:#ff8c00;border:none;border-radius:8px;color:#FFFF;font-size:12px" @submit="contactPoopup=false" class="btn-cancel-booking" content="了解しました"></Button>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="dialog-cancle-booking" :visible.sync="dialogLastChoice" width="30%" center>
      <div>
        <div class="modal-body content_popup">
          <div class="custom-col el-form-custom text-center dialog-contact-content contact-content">
            ご予約をキャンセルします。よろしいでしょうか。
          </div>
          <div style="display:flex; justify-content: space-around; width:100%">
            <Button customStyle="border-radius:8px; max-width:50%; min-width:25%; padding:5px 0;background-color:#ff8c00;border:none;color:#FFFF" @submit="dialogLastChoice = false; reasonActive = ''; confirmLastChoiceCancel()" class="btn-cancel-booking" content="OK"></Button>
            <Button customStyle="border-radius:8px; max-width:50%; min-width:25%; padding:5px 0" @submit="dialogLastChoice = false; reasonActive = ''; choosingReason = ''" class="btn-cancel-booking" content="戻る"></Button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- end dialog cancel popup -->
  </div>
</template>


<script>

import moment from "moment";
import Button from "@/components/layout/components/ButtonCommon";
import ApiService from "@/core/services/api.service";
import {mapActions} from "vuex";
import {DATE_BOOKING, SET_ALL_CHOOSE_PROCESS, TYPE_CUSTOMER} from "@/core/services/store/booking.module";

export default {
  name: 'history',
  components : {
    Button
  },
  data() {
    return {
      asd:true,
      year: "",
      month: "",
      moment: moment,
      listBooking: {
        visitDateTime: '',
        combo: {
          name: '',
          image: '',
        },
        service: {
          name: '',
          image: '',
        }
      },
      checkStatus: false,
      dialogCancel: false,
      idBooking: '',
      contactPoopup:false,
      reasonActive:'',
      dialogLastChoice: false,
      choosingReason : ''
    };
  },
  computed: {
    checkCurrenDevice() {
      return this.checkDevice();
    }
  },
  created() {
    this.getCurrentDate();
  },
  methods: {
    ...mapActions([TYPE_CUSTOMER, SET_ALL_CHOOSE_PROCESS, DATE_BOOKING]),

    formatTime(time) {
      return time ? moment(time).format('HH:mm:ss') : ''
    },

    closeModalCancelPopup() {
      this.dialogCancel = false;
    },

    async showPoopupBoooking(item, flag = false) {
      await ApiService.post(`booking/check-cancel-boooking/${item.id}`)
        .then(({data}) => {
           if(data.code === 200) {
              if (data.data.check){
                if (flag) {
                  this.rebooking(item)
                }else {
                  this.idBooking = item.id;
                  this.dialogCancel = true;
                }
              }else{
                this.contactPoopup = true
              }

           }
        })
        .catch((error) => {
          if (error.response.status !== 401) {
            this.notifyError(error.response.message)
          }
        });
    },

    formatDate(date) {
      return date ? moment(String(date)).format('YYYY/MM/DD') : '';
    },

    getCurrentDate() {
      const dateObj = new Date();
      this.year = dateObj.getFullYear();
      this.month = dateObj.getMonth()+1;
      this.getList();
    },

    async getList() {
      this.startLoading();
      const date = this.year + '-' + this.month;
      const dateFormat = date ? moment(date, 'YYYY-MM').format('YYYY-MM') : '';
      await ApiService.get(`history?month=` + dateFormat)
          .then(({data}) => {
            this.endLoading();
            this.listBooking = data.data
          })
          .catch((error) => {
            this.endLoading();
            if (error.response.status !== 401) {
              this.notifyError(error.response.message)
            }
          });
    },
    async confirmCancelBooking(reason_id) {
      this.choosingReason = reason_id;
      this.dialogCancel = false;
      this.dialogLastChoice = true;
    },
    async confirmLastChoiceCancel(){
      this.startLoading();
      this.dialogLastChoice = false;
      await ApiService.post(`booking/cancel-booking/${this.idBooking}/${this.choosingReason}`)
          .then(({data}) => {
            this.endLoading();
            this.choosingReason = ''
            if(data.data.code === 200) {
              this.$notify.success({
                title: '成功',
                message: 'ご予約をキャンセルしました',
                showClose: false
              })
              this.idBooking = ''
              this.getList();
            }
          })
          .catch((error) => {
            this.endLoading();
            if (error.response.status !== 401) {
              this.notifyError(error.response.message)
            }
          });
    },
    nextMonth() {
      let day = moment(this.year + '-' + this.month + '-01','yyyy-MM-DD').add(1, 'M')
      this.month = day.format('MM')
      this.year = day.format('YYYY')
      this.getList();
    },

    preMonth() {
      let day = moment(this.year + '-' + this.month + '-01','yyyy-MM-DD').subtract(1, 'M')
      this.month = day.format('MM')
      this.year = day.format('YYYY')
      this.getList();
    },
    rebooking(booking) {
      this.typeCustomer(booking.type);
      booking.service.map(service => {
        booking.service_process.forEach(process => {
          if (process.service_id === service.id) {
            service.processItem = process
            service.type = 1
          }
        })
        return service
      })
      booking.combos.map(combo => {
        booking.combo_process.forEach(process => {
          if (process.combo_id === combo.id) {
            combo.processItem = process
            combo.type = 2
          }
        })
        return combo
      })
      this.setAllChooseProcess([...booking.service, ...booking.combos]);
      booking.detail.forEach((value) => {
        value.start_time = moment(value.start_time,'HH:mm:ss').format("HH:mm")
      })
      this.dateBook(booking.detail);
      this.$router.push({ name: 'choose-type-user', query: { id: booking.id } })
    }
  }
}
</script>
<style scoped>
.change-background {
  background-color: #130a0a;
  opacity: 0.1;
}
.grid-content.bg-purple {
  padding-right: 10px;
}
button.el-button.btn-rebooking.el-button--default {
  min-width: 30%;
  background-color: #ffc063;
  color: #fff;
}
button.el-button.btn-booking-delete.el-button--default {
  min-width: 30%;
  background-color: #c95c55;
  color: #fff;
}
.history-booking {
  width: 100%;
  height: 50%;
  padding: 20px 20px 20px 50px;
  background-color: #fff;
  border: 1px solid #c7c3c3;
  margin-bottom: 50px;
  padding-bottom: 50px;
}
.container {
  background-color: #f2f2f2;
}
.custom-btn-booking-detail.el-row {
  top: 50px;
}
.custom-title-h3 {
  text-align: center !important;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #fff;
  background-color: #85ce61;
}
button.btn.btn-back.btn-secondary {
  background-color: #fff;
  color: black;
  font-weight: 600;
}
button.btn.btn-cancel.btn-secondary {
  background-color: #c95c55;
  border-color: #c95c55;
}
.content-header {
  font-size: 24px;
  font-weight: 500;
}
header {
  padding: 18px;
  background-color: #191970;
  color: white;
}
.card-title {
  color: #191970;
}
.pr--3 {
  padding-right: 12px;
}
.detail-item {
  font-weight: bold;
}
.selet-date{
  color: #191970;
  font-weight: bold;
  font-size: 19px;
}
.deatil-date {
  color: #191970;;
  font-size: 16px;
  font-weight: 500;
}
.btn-cancel-booking {
  border: 1px solid #191970;
  background-color: white;
  color: #191970;
}
.btn-cancel-booking-active {
  border: none;
  background-color: #191970;
  color: white ;
}
.bg-gainsboro {
  background-color: gainsboro;
}
.dialog-contact-content {
  line-height: 30px;
}
.contact-content{
  font-size: 22px;
}
@media only screen and (max-width: 600px) {
  .detail-item{
    font-size: 16px;
  }
  .sub-card-body {
    padding-right: 0;
  }
  .btn-cancel-booking {
    padding: 10px 3px;
  }
}
@media only screen and (max-width: 375px) {
  .detail-item{
    font-size: 13px;
  }
}
</style>
