import Vue from "vue";
import Vuex from "vuex";
import error from "./error.module"
import lineCode from "./line.module"
import loading from "./loading.module"
import auth from "./auth.module"
import ticket from "./ticket.module"
import payment from "./payment.module"
import booking from "./booking.module"
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    error,
    loading,
    auth,
    lineCode,
    ticket,
    payment,
    booking
  },
  plugins: [createPersistedState({
    paths: ['lineCode','booking'],
  }),],
});
