<template>
    <div>
      <div class="title-ticket">
        <h2>マイチケット</h2>
      </div>
      <div class="expired">
        <div v-for="(item, index) in this.data_expired" :key="index">
          <div class="text item ticket_item">
            <p class="text-title ticket-name">{{ item.name }}</p>
            <p class="text-title">購入数 : <b>{{ item.use_total }}</b></p>
            <p class="text-title">利用回数 : <b>{{ item.use_count }}</b></p>
            <p class="text-title">残り回数 : <b>{{ item.ticket_rest }}</b></p>
            <button class="text-title button-ticket">有効期限 : <b>{{ formatYear(item.expire_date) }}</b> 年 <b>{{ formatMonth(item.expire_date) }}</b> 月 <b>{{ formatDay(item.expire_date) }}</b> 日 </button>
          </div>
        </div>
        <div class="text item ticket_item" v-if="this.data_expired.length==0">
          <p class="text-title ticket-name">チケットがありません。</p>
        </div>
      </div>

      <div class="title-ticket-2">
        <h1>有効期限切れのチケット</h1>
      </div>
      <div class="expire">
        <div v-for="(item, index) in this.data_expire" :key="index">
          <div class="text item ticket_item_2">
            <p class="text-title-2 ticket-name">{{ item.name }}</p>
            <p class="text-title-2">購入数 : <b>{{ item.use_total }}</b></p>
            <p class="text-title-2">利用回数 : <b>{{ item.use_count }}</b></p>
            <p class="text-title-2">残り回数 : <b>{{ item.ticket_rest }}</b></p>
            <button class="text-title-2 button-ticket">有効期限 : <b>{{ formatYear(item.expire_date) }}</b> 年 <b>{{ formatMonth(item.expire_date) }}</b> 月 <b>{{ formatDay(item.expire_date) }}</b> 日 </button>
          </div>
        </div>
        <div class="text item ticket_item" v-if="this.data_expire.length==0">
          <p class="text-title ticket-name">チケットがありません。</p>
        </div>
    </div>
  </div>
</template>

<script>
import {LIST_TICKET} from "@/core/services/store/ticket.module";
import Button from "@/components/layout/components/ButtonCommon";
import moment from 'moment';

export default {
  name: 'Ticket',
  components : {
    Button
  },
  data() {
    return {
      data: {},
      data_expired: [],
      data_expire: [],
      checkDate: false,
      moment: moment,
    };
  },

  async created() {
    await this.getList();
  },

  methods: {
     formatYear(year) {
      return year ? moment(year).format('yyyy') : ''
    },
     formatMonth(month) {
      return month ? moment(month).format('MM') : ''
    },
     formatDay(day) {
      return day ? moment(day).format('DD') : ''
    },
    async getList() {
      let data = await this.$store.dispatch(LIST_TICKET);
      if (data.code === 200) {
        this.data = data.data.data
        this.data.forEach((value, index) => {
          if ((value.use_total - value.use_count > 0) && (moment(value.expire_date).isAfter())) {
            this.data_expired.push(value)
          }
          if ((value.use_total - value.use_count <= 0) || (!moment(value.expire_date).isAfter())) {
            this.data_expire.push(value)
          }
        })
      }
    },

  },

}
</script>
<style scoped>
.text-title {
  color: #191970;
  font-size: 20px;
}
.ticket-name {
  text-align: center;
  font-size: 26px;
}
.text-title:last-child{
  margin-bottom: 0;
}
.expired {
  overflow: auto;
  padding: 5% 3%;
}

.expire {
  height: 500px;
  overflow: auto;
  padding: 5% 3%;
}
.title-ticket{
  background-color: #191970;
  color: #ffffff;
  text-align: center;
  padding: 5% 0;
}
.title-ticket-2 {
  color: #898989;
  text-align: center;
  padding-top: 5%;
}
.title-ticket h2 {
  font-size: 40px;
  margin-bottom: 0;
}
.ticket_item {
  background-color: #ffffff;
  box-shadow: rgba(0,0,0,0.39215686274509803 );
  border-radius: 3px;
  padding: 25px;
}
.ticket_item_2 {
  background-color: #cccccc;
  box-shadow: rgba(0,0,0,0.39215686274509803 );
  border-radius: 3px;
  padding: 25px;
}
.text-title-2{
  color: #191970;
  font-size: 20px;
}
.button-ticket {
  background: transparent;
  border: 1px solid;
  width: 100%;
  border-radius: 10px;
  padding: 5px 0;
}
@media only screen and (max-width: 425px) {
 .title-ticket h2 {
   font-size: 25px
 }
 .text-title,
 .text-title-2 {
   font-size: 16px;
 }
}
</style>
