import JwtService from "../jwt.service"
import ApiService from "../api.service";
import router from "../../../router"

export const LOGIN_LINE = "login_line";
export const LOGOUT = "logout";
export const SET_ERROR = "setError";
export const VERIFY_AUTH = "verifyAuth";

export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";

const state = {
    user: {},
    errors: null,
    isAuthenticated: !!JwtService.getToken(),
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    errors(state) {
        return state.errors;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
};

// actions
const actions = {
    [LOGIN_LINE](context, credentials) {
        return new Promise(resolve => {
            ApiService.post("/auth/loginToProvider", credentials)
                .then(({data}) => {
                    data.data.original.data.user.token = data.data.original.data.token
                    context.commit(SET_AUTH, data.data.original.data);
                    ApiService.setHeader();
                    resolve(data);
                    if(data.data.original.data.user.email === null) {
                      router.push({name: "register"});
                    }
                })
                .catch(response => {
                    resolve();
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [VERIFY_AUTH](context, param) {
        return new Promise(resolve => {
            if (param.to.name !== 'login' && !param.isAuthenticated && !param.to.meta.notAuthenticated) {
                router.push({ name: "login", query: {redirect: param.to.path} });
            }
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("/auth/verify")
                        .then(({data}) => {
                            if (!data.data.user) {
                                data.data.user = {}
                            }
                            data.data.user.token = data.data.token;
                            context.commit(SET_AUTH, data.data);
                            if(param.to.name !== 'register' && data.data.user.email === null) {
                              router.push({ name: "register", query: {type: param.to.path} });
                            }
                            resolve(data);
                        }).catch(({response}) => {
                            router.push({ name: "login", query: {redirect: param.to.path} });
                            resolve();
                        });
            } else {
                resolve();
                context.commit(PURGE_AUTH);
            }
        })
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
}

const mutations = {
    [SET_ERROR](state, error_time) {
        state.error_time = error_time
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true;
        state.user = user.user;
        state.errors = {};
        JwtService.saveToken(state.user.token);
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        JwtService.destroyToken();
    }
}

export default {
    state,
    actions,
    mutations,
    getters,
};
