// action types
export const SET_ERROR = "setError";
export const SET_START_BLOCK_TIME = "setBlockTime";
export const SET_ALL_ERROR = "setAllError"

const state = {
    error_time: 0,
    start_block_time: null,
    allErrors: []
};

const getters = {
    currentErrorTime(state) {
        return state.error_time;
    },
    startBlockTime(state) {
        return state.start_block_time
    },
    allErrors(state) {
      return state.allErrors
    }
};

// actions
const actions = {
    [SET_ERROR](context, error_time) {
        context.commit(SET_ERROR, error_time);
    },
    [SET_START_BLOCK_TIME](context, start_block_time) {
        context.commit(SET_START_BLOCK_TIME, start_block_time);
    },
    [SET_ALL_ERROR](context, allErrors) {
      context.commit(SET_ALL_ERROR, allErrors);
    },
}

const mutations = {
    [SET_ERROR](state, error_time) {
        state.error_time = error_time
    },
    [SET_START_BLOCK_TIME](state, start_block_time) {
        state.start_block_time = start_block_time
    },
    [SET_ALL_ERROR](state, allErrors) {
        state.allErrors = allErrors
    },
}

export default {
    state,
    actions,
    mutations,
    getters,
};
