import { TicketApi } from "@/core/services/apis/ticket";

export const LIST_TICKET = 'listTicket';

const state = {

};

const getters = {

};

// actions
const actions = {

  async [LIST_TICKET](context, params) {
    try {
      const response = await TicketApi.listTicket(params);
      return response.data;
    } catch (e) {
      return e;
    }
  },
}

const mutations = {

}

export default {
  state,
  actions,
  mutations,
  getters,
};
