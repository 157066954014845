// action types
export const SET_TOKEN = "setCode";

const state = {
    code: null,
};

const getters = {
    currentCode(state) {
        return state.code;
    },
};

// actions
const actions = {
    [SET_TOKEN](context, code) {
        context.commit(SET_TOKEN, code);
    },
}

const mutations = {
    [SET_TOKEN](state, code) {
        state.code = code
    },
}

export default {
    state,
    actions,
    mutations,
    getters,
};
