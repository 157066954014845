import env from "../core/config/env";

const comboListRouter = [
    {
        path: env.PREFIX_URL + `/service/:id`,
        name: "service-detail",
        meta: {
            notAuthenticated: true,
            title: `${env.DOMAIN_TITLE} | サービス詳細`
        },
        component: () => import("@/components/pages/service/Detail"),
    }
];

export default comboListRouter
