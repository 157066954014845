<template>
  <div>
    <div>
      <img src="../../../../public/img/headers.png" class="img-fluid img-header " alt="...">
    </div>
  </div>
</template>

<script>

export default {
  name: "KTHeader",
  data() {
    return {
      tabIndex: 0
    };
  },

  computed: {
  },
  methods: {
  }
};
</script>
