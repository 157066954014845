import Vue from 'vue';
import axios from 'axios';
import env from "../config/env";
import JwtService from "@/core/services/jwt.service";

import VueAxios from "vue-axios";

/**
 * Service to call HTTP request via Axios
 */
class ApiService {
  $baseURL = null
  $http = null

  init = (options) => {
    options = options || {}
    const baseURL = options.url ? options.url : env.API_URL
    Vue.use(VueAxios, axios)

    this.$baseURL = baseURL

    this.$http = Vue.axios
    this.$http.defaults.baseURL = baseURL
    this.$http.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
  };

  /**
   * Set the default HTTP request headers
   */
  setHeader = () => {
    this.$http.defaults.headers.common[
            "Authorization"
            ] = `Bearer ${JwtService.getToken()}`;
    // this.$http.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  };

  query = (resource, params) => {
    const options = this._getOptions()
    return this.$http.get(resource, params, options).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService 1 ${error}`)
    });
  };

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param payload
   * @returns {*}
   */
  get = (resource, slug="", payload = {}) => {
    const url = this._getUri(resource, slug)
    return axios.get(`${url}`)
  };

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param payload
   * @returns {*}
   */
  /*post = (resource, params, payload) => {
    const options = this._getOptions(payload)
    return this.$http.post(`${resource}`, params, options)
  };
*/
  post = (resource, params, headers) => {
    return axios.post(resource, params, { headers })
  };
  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @param payload
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update = (resource, slug, params, payload) => {
    const options = this._getOptions(payload)
    return this.$http.put(`${resource}/${slug}`, params, options);
  };

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @param payload
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put = (resource, params, payload) => {
    const options = this._getOptions(payload)
    return this.$http.put(`${resource}`, params, options)
  };

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param payload
   * @returns {*}
   */
  delete = (resource, payload) => {
    const options = this._getOptions(payload);
    return this.$http.delete(resource, options).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`)
    });
  };
  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  _getUri = (resource, slug= '') => {
    let uri = (slug !== '' && slug !== undefined )? `${resource}/${slug}` : resource
    return uri
  };
  /**
   * get config option, baseURL
   * @param resource
   * @returns {*}
   */
  _getOptions = (payload) => {
    let options = payload || {}
    if (!options.baseURL) {
      options.baseURL = this.$baseURL
    }

    return options
  };
}

// create instance
const apiService = new ApiService()
apiService.init()
apiService.setHeader()
// lock instance
Object.freeze(apiService)

export default apiService
