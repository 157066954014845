export const ENV = {
  API_MODE: 'api',// json
  // API_URL: 'http://localhost:8000/server/api/customer',
  API_URL: 'https://api.greenripples.net/server/api/user',
  SESSION_ID: 1,
  LOGIN_LINE: {
    authorize_url: 'https://access.line.me/oauth2/v2.1/authorize',
    redirect_url: 'https://greenripples.net/login',
    redirect_url_verify_user: 'https://greenripples.net/verify-user',
    token_url: 'https://api.line.me/oauth2/v2.1/token',
    client_id: '1657067991',
    clientSecret: 'bdf63d6145103055163c7bdc5dec2309'
  },
  PREFIX_URL: '',
  DOMAIN_TITLE: 'ぐり〜んりぷる'
}
export default ENV;

