<template>
  <div>
    <div class ="header">
      <h2 class="label-two">プライバシーポリシー</h2>
    </div>
    <div class="common-box pb-4">
      <div class="content">
        <div class="overflow-auto" v-html="form.privacy"></div>
        <div class="justify-content-center d-flex">
          <Button customStyle="width:50%" @submit="$router.go(-1)" customClassName="border-none-and-background-aqua">戻る</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {DETAIL_POLICY} from "@/core/services/store/policy.module";
  import Button from "@/components/layout/components/ButtonCommon";
  export default {
    name: 'privacy',
    props: {},
    components : {
      Button
    },
    data () {
      return {
        form: {
          privacy: '',
          term: ''
        }
      };
    },

    created () {
      this.detailPolicy();
    },

    methods: {
      async detailPolicy () {
        const { data } = await DETAIL_POLICY.detail();
        this.form = data.data
      }
    }

  }
</script>

<style scoped>
.header{
  height: 70px;
  background-color: #191970;
}
.label-two{
  color: white;
  font-size: 19px;
  text-align: center;
  line-height: 70px;
}
.parent{
  height: 800px;
}
.content {
  background-color: white;
  padding: 23px;
}
.el-icon-arrow-left{
  display: inline-block;
  width: 100px;
  height: 100px;
  left: 10px;
  top: 13%;
  font-size: 30px;
  color:aliceblue
}
</style>
