<template>
  <div>
    <section>
      <div class="w--100 accordion">
        <el-steps :space="200" :active="step" align-center style="font-size: 14px">
          <el-step title="メニュー"></el-step>
          <el-step title="予約"></el-step>
          <el-step title="情報入力"></el-step>
          <el-step title="予約手続き"></el-step>
        </el-steps>
<!--        <div class="accordion" id="accordionExample">-->
<!--          <div class="steps">-->
<!--            <progress id="progress" value=100 max=150 ></progress>-->
<!--            <div class="step-item">-->
<!--              <button class="step-button text-center" type="button" data-toggle="collapse"-->
<!--                      data-target="#collapseOne" :aria-expanded="process_value == 0 ? true : false" aria-controls="collapseOne">-->
<!--                1-->
<!--              </button>-->
<!--              <div class="step-title">-->
<!--                メニュー-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="step-item">-->
<!--              <button class="step-button text-center collapsed" type="button" data-toggle="collapse"-->
<!--                      data-target="#collapseTwo" :aria-expanded="process_value == 50 ? true : false" aria-controls="collapseTwo">-->
<!--                2-->
<!--              </button>-->
<!--              <div class="step-title">-->
<!--                予約-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="step-item">-->
<!--              <button class="step-button text-center collapsed" type="button" data-toggle="collapse"-->
<!--                      data-target="#collapseThree" :aria-expanded="process_value == 100 ? true : false" aria-controls="collapseThree">-->
<!--                3-->
<!--              </button>-->
<!--              <div class="step-title">-->
<!--                備報入力-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="step-item">-->
<!--              <button class="step-button text-center collapsed" type="button" data-toggle="collapse"-->
<!--                      data-target="#collapseFor" :aria-expanded="process_value == 150 ? true : false" aria-controls="collapseFor">-->
<!--               4-->
<!--              </button>-->
<!--              <div class="step-title">-->
<!--                予約手続き-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      process_value: 0
    };
  },
  watch:{
    step(value) {
      if (Number.isInteger(value) && (value > 0 && value < 5)) {
         this.process_value = (value -1 ) * 50
      }
    }
  }
};
</script>

<style scoped>
.btn_submit_dialog {
  font-family: kzk_bold;
  color: #fff;
  background: #FC9220;
}
</style>
