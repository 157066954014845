<template>
  <div>
    <h3 class="text-center custom-title-h3">レビュー</h3>
    <div class="enter-user-body review">
      <el-form label-width="140px" v-model="reviewData" ref="reviewData" class="el-form--label-left">
        <el-form-item prop="anony" label="匿名" class="start-required text-bold style-txt">
          <el-input
            maxLength="20"
            @input.native="(e)=>{lengthInput(e, 20)}"
            v-model="reviewData.anony"
            placeholder="公式掲載用ペンネーム">
          </el-input>
        </el-form-item>
        <el-form-item prop="age" label="年齢" class="start-required text-bold enter-user-radio">
          <el-select v-model="reviewData.age" placeholder="Select">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="gender" label="性別" class="start-required text-bold enter-user-radio">
          <el-radio-group v-model="reviewData.gender">
            <el-radio :label="1">男性</el-radio>
            <el-radio :label="2">女性</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="birthday" label="利用日" class="start-required text-bold">
          <el-date-picker class="customer-date-picker"
                          type="date"
                          :clearable="false"
                          placeholder="入力してください"
                          style="width: 100%;"
                          format="yyyy/MM/dd"
                          value-format="yyyy-MM-dd"
                          @input="checkFullWithBirth"
                          onkeydown="javascript: return event.keyCode === 8
                      ||event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                          v-model="reviewData.date">

          </el-date-picker>
        </el-form-item>
        <el-form-item prop="name" label="ご利用メニュー" class="start-required text-bold enter-user-radio">
          <el-input
            class="textarea_confirm_booking"
            maxLength="500"
            @input.native="(e)=>{lengthInput(e, 500)}"
            v-model="reviewData.name"
            type="textarea"
            :autosize="{ minRows: 3}"
            placeholder="">
          </el-input>
        </el-form-item>
        <el-form-item prop="content" label="コメント　　　　　（1000文字まで）" class="start-required text-bold enter-user-radio style-text">
          <el-input
            class="textarea_confirm_booking"
            maxLength="1000"
            @input.native="(e)=>{lengthInput(e, 1000)}"
            v-model="reviewData.content"
            type="textarea"
            :autosize="{ minRows: 8}"
            placeholder="">
          </el-input>
        </el-form-item>
        <el-form-item label="画像アップロード（2枚まで）" class="start-required text-bold enter-user-radio style-radio">
          <el-row>
            <el-col :xs="23" :sm="11" :md="11" :lg="11" :xl="11" class="style-second">
              <form class="uploader">
                <input id="file-upload" type="file" name="fileUpload" accept="image/*" @change="uploadImage($event)" />
                <i v-if="imageUrl" class="el-icon-error clear-icon" @click="rmImage()"></i>
                <label for="file-upload">
                  <img v-if="imageUrl" class="file-image" :src="imageUrl" alt="Preview">
                  <div v-else class="start">
                    <i class="el-icon-upload" style="font-size: 50px"></i>
                    <div>アップロード（最大画像サイズ：10MB）</div>
                  </div>
                </label>
              </form>
            </el-col>
            <el-col :xs="23" :sm="11" :md="11" :lg="11" :xl="11" class="image-second">
              <form class="uploader">
                <input id="file-upload-1" type="file" name="fileUpload" accept="image/*" @change="uploadImageSecond($event)" />
                <i v-if="imageUrl2" class="el-icon-error clear-icon" @click="rmImageSecond()"></i>
                <label for="file-upload-1">
                  <img v-if="imageUrl2" class="file-image" :src="imageUrl2" alt="Preview">
                  <div v-else class="start">
                    <i class="el-icon-upload" style="font-size: 50px"></i>
                    <div>アップロード（最大画像サイズ：10MB）</div>
                  </div>
                </label>
              </form>
            </el-col>
          </el-row>

        </el-form-item>
        <div class="d-flex justify-content-center btn-enter-user">
          <Button customStyle="width:47%" :custom-class-name="'border-aqua-and-background-white'" @submit="backToLineApp">戻る</Button>
          <Button customStyle="width:47%; margin-left: 20px" @submit="editProfile">保存</Button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import moment from 'moment';
import Button from "@/components/layout/components/ButtonCommon";
import {mapGetters} from "vuex";
import * as constants from "@/core/config/constant";

export default {
  name: "profile",
  components : {
    Button
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  data() {
    return {
      options: [
        {value: '10', label: '10代'},
        {value: '20', label: '20代'},
        {value: '30', label: '30代'},
        {value: '40', label: '40代'},
        {value: '50', label: '50代'},
        {value: '60', label: '60代'},
        {value: '70', label: '70代'},
        {value: '80', label: '80代'},
        {value: '90', label: '90代'},
        {value: '100', label: '100代'},
      ],
      reviewData: {
        anony: '',
        age: '20',
        gender: 1,
        date: moment().format('yyyy-MM-DD'),
        name: '',
        content: '',
        image1: '',
        image2: '',
      },
      constants: constants,
      imageUrl: '',
      imageUrl2: '',
      store: {},
      checkBoxItemLine: false,
      checkBoxItemEmail: false,
      photo: null
    }
  },

  watch: {
    reviewData(val) {
      if(val) {
        this.checkBoxItemLine = val.notify_line ? true : false
        this.checkBoxItemEmail = val.notify_email ? true : false
      }
    },
    'checkBoxItemLine' () {
      if (!this.checkBoxItemLine && !this.checkBoxItemEmail) {
        this.checkBoxItemLine = true
      }
    },
    'checkBoxItemEmail' () {
      if (!this.checkBoxItemEmail && !this.checkBoxItemLine) {
        this.checkBoxItemLine = true
      }
    }
  },

  methods: {
    checkFullWithBirth(e) {
      this.reviewData.birthday = e.replace(/[！-～]/g, r => String.fromCharCode(r.charCodeAt(0) - 0xFEE0));
    },
    editProfile() {
      this.startLoading()
      this.$refs.reviewData.clearValidate(this.reviewData)
      let formData = new FormData();
      formData.append('anony', this.reviewData.anony);
      formData.append('age', this.reviewData.age);
      formData.append('gender', this.reviewData.gender);
      formData.append('date', this.reviewData.date ? moment(this.reviewData.date).format('yyyy-MM-DD') : '');
      formData.append('name', this.reviewData.name);
      formData.append('content', this.reviewData.content);
      if (this.imageUrl) {
        formData.append('image1', this.reviewData.image1);
      }
      if (this.imageUrl2) {
        formData.append('image2', this.reviewData.image2);
      }
      ApiService.post(`review`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          this.endLoading()
          this.notifySuccess('口コミのご協力をいただきましてありがとうございます。(抜粋し、ホームページへの掲載/SNSでの投稿をさせていただきます。)')
          setTimeout(() => {
            this.backToLineApp()
          }, 6000)
        })
        .catch(error => {
          this.endLoading()
          if (error.response.status === 422) {
            this.showErrorMessage(error.response.data.data, "reviewData");
          } else if (error.response.status === 500) {
            this.notifyError(error.response.statusText)
          } else {
            if (error.response.status !== 401) {
              this.notifyError(error.response.message)
            }
          }
        });
    },

    checkImage(input) {
      if (input.files && input.files[0]) {
        let file = input.files[0];
        let size = Math.round((file.size / 1024));
        let arrayType = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!arrayType.includes(file.type)) {
          this.notifyError('タイプのファイルはjpeg、jpg、pngしかでアップロードできません。')
          return false
        }
        if (size > constants.MAX_SIZE_FILE) {
          this.notifyError('ファイル容量が大きすぎて、システムにアップロードできませんです。')
          return false
        }
        return true
      }
      return false
    },

    uploadImage(event) {
      let input = event.target;
      if (this.checkImage(input)) {
        this.imageUrl = URL.createObjectURL(event.target.files[0])
        this.reviewData.image1 = event.target.files[0]
      }
    },

    uploadImageSecond(event) {
      let input = event.target;
      if (this.checkImage(input)) {
        this.imageUrl2 = URL.createObjectURL(event.target.files[0])
        this.reviewData.image2 = event.target.files[0]
      }
    },

    rmImage() {
      this.imageUrl = ''
      this.reviewData.image1 = ''
    },

    rmImageSecond() {
      this.imageUrl2 = ''
      this.reviewData.image2 = ''
    }
  }
};
</script>
<style>
.notification .el-dialog__body .content {
  font-size: 18px;
}
@media only screen and (max-width: 1280px) {
  .notification .el-dialog__body .content {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1024px) {
  .notification .el-dialog__body .content {
    font-size: 23px;
  }
}
@media only screen and (max-width: 992px) {
  .notification .el-dialog__body .content {
    font-size: 19px;
  }
}
@media only screen and (max-width: 767px) {
  .style-text label {
    font-size: 13px !important;
  }
  .notification .el-dialog__body .content {
    font-size: 12.5px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 667px) {
  .enter-user-body {padding: 2% 3% !important;}
}
@media only screen and (max-width: 667px) {
  .enter-user-body {padding: 2% 3% !important;}
  .style-text label {
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 375px) {
  .notification .el-dialog__body .content {
    font-size: 11px;
  }
}
</style>
<style scoped>
.text-bold{
  font-weight: 600;
}
.custom-title-h3{
  color: #191970;
  font-weight: 700;
  padding: 30px 0px;
}

.pt {
  position: absolute;
  right: 10px;
  font-size: 13px;
  color: #606266;
  font-weight: 600;
}
.description {
  font-weight: 600;
  color: #8F8D89;
  font-size: 12px;
}
.btn-enter-user > button , .btn-enter-user > button > span > i{
  font-weight: 600;
  font-size: 20px;
}
.preview-contract {
  color: #66B0D1;
  font-weight: bold;
  font-size: 17px;
}
.col-ting {
  width: 25em;
  margin: 0 auto;
  margin-top: 3em;
  margin-bottom: 3em;
}
.image-box {
  margin: 0 auto;
  margin-top: 1em;
  height: 15em;
  width: 20em;
  background: #d24d57;
  cursor: pointer;
  overflow: hidden;
}

img {
  height: 100%;
  display: none;
}

p {
  position: relative;
  top: 45%;
  color: #fff;
}

.uploader {
  position: relative;
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}
.uploader label {
  float: left;
  clear: both;
  width: 100%;
  padding: 2rem 1.5rem;
  text-align: center;
  background: #fff;
  border-radius: 7px;
  border: 3px solid #eee;
  transition: all 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.uploader label:hover {
  border-color: #454cad;
}
.uploader label.hover {
  border: 3px solid #454cad;
  box-shadow: inset 0 0 0 6px #eee;
}
.uploader label.hover .start i.fa {
  transform: scale(0.8);
  opacity: 0.3;
}
.uploader .start {
  cursor: pointer;
  float: left;
  clear: both;
  width: 100%;
}
.uploader .start.hidden {
  display: none;
}
.uploader .start i.fa {
  font-size: 50px;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
}
.uploader #response {
  float: left;
  clear: both;
  width: 100%;
}
.uploader #response.hidden {
  display: none;
}
.uploader #response #messages {
  margin-bottom: 0.5rem;
}
.uploader .file-image {
  display: inline;
  margin: 0 auto 0.5rem auto;
  object-fit: contain;
  width: 174px;
  height: 108px;
}
.uploader .file-image.hidden {
  display: none;
}
.uploader .notimage {
  display: block;
  float: left;
  clear: both;
  width: 100%;
}
.uploader .notimage.hidden {
  display: none;
}
.uploader progress,
.uploader .progress {
  display: inline;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 180px;
  height: 8px;
  border: 0;
  border-radius: 4px;
  background-color: #eee;
  overflow: hidden;
}
.uploader .progress[value]::-webkit-progress-bar {
  border-radius: 4px;
  background-color: #eee;
}
.uploader .progress[value]::-webkit-progress-value {
  background: linear-gradient(to right, #393f90 0%, #454cad 50%);
  border-radius: 4px;
}
.uploader .progress[value]::-moz-progress-bar {
  background: linear-gradient(to right, #393f90 0%, #454cad 50%);
  border-radius: 4px;
}
.uploader input[type=file] {
  display: none;
}
.uploader div {
  margin: 0 0 0.5rem 0;
  color: #5f6982;
}
.uploader .btn {
  display: inline-block;
  margin: 0.5rem 0.5rem 1rem 0.5rem;
  clear: both;
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  text-transform: initial;
  border: none;
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  height: 36px;
  line-height: 36px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  background: #454cad;
  border-color: #454cad;
  cursor: pointer;
}
.clear-icon {
  font-size: 50px;
  color: red;
  position: absolute;
  right: -25px;
  top: -22px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}
.image-second {
  margin-left: 30px;
}
@media only screen and (max-width: 600px) {
  .image-second {
    margin-left: 0;
    margin-top: 30px;
  }
}
</style>
