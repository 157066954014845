<template>
  <div class="content">
    <process :step="1"></process>
    <div class="text-center header-calenda">
      <a href="javascript:void(0)" v-if="!moment(year + '-' + month, 'YYYY-MM').isBefore(moment())" @click="preMonth()"> <i class="el-icon-arrow-left"></i> </a>
      <a href="javascript:void(0)" v-else style="color:gray;"> <i class="el-icon-arrow-left"></i> </a>
      <span class="year"> {{ year }}.{{ month }} </span>
      <a href="javascript:void(0)" @click="nextMonth()"> <i class="el-icon-arrow-right"></i> </a>
    </div>
    <div class="month-days">
      <div class="row list-day" v-if="days.length > 0">
        <div v-for="(item,index) in days" :key="index" class="col-item-day">
          <button class="button-day button-common" type="button" :disabled="moment(item, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))" :class="{'active': moment(item, 'YYYY-MM-DD').isSame(moment(day, 'YYYY-MM-DD')) }" @click="getChooseDate(moment(item, 'YYYY-MM-DD').format('YYYY-MM-DD'))">
            {{ moment(item, 'YYYY-MM-DD').format('DD') }}
            <p class="text-item">{{ getDayJapanese(moment(item, 'YYYY-MM-DD').day()) }}</p>
          </button>
        </div>
      </div>
    </div>
    <div class="group-time">
      <el-row v-if="groupTime.length > 0" class="time-margin">
        <el-col :span="6" v-for="(item,index) in groupTime" :key="index" class="col-time">
          <button class="button-time button-common" type="button"
                  :class="{'active': item == time }"
                     @click="getChooseTime(item)"
                     :disabled="blockHour(item,listBlockHour)
                     || checkCurrentDate
                     || !day
                     || blockHour(item,listStoreSchedule)
                     || blockHour(item,blockTime24h)">
            {{ item }}
          </button>
        </el-col>
      </el-row>
    </div>
    <div class="text-center pb-3 pt-3">
      <Button customStyle="min-width: 296px" @submit="nextStep()">ご予約に進む<span style="padding-left: 10px"></span></Button>
    </div>
  </div>
</template>

<script>

import ApiService from "@/core/services/api.service";
import {mapGetters, mapActions} from "vuex";
import {DATE_BOOKING} from "@/core/services/store/booking.module";
import moment from "moment";
import * as CONSTANTS from "@/core/config/constant";
import process from "@/components/layout/components/process";
import Button from "@/components/layout/components/ButtonCommon";

export default {
  name: "NewCustomer",
  components : {
    process,
    Button
  },
  data() {
    return {
      year: '',
      month: '',
      days: [],
      groupTime: [],
      day: moment().add('1', 'days').format('yyyy-MM-DD'),
      time: '',
      store_id: '',
      moment: moment,
      listBlockHour: [],
      currentTime:0,
      checkCurrentDate: false,
      listStoreSchedule: [],
      blockTime24h: []
    };
  },

  computed: {
    ...mapGetters(["dataProcess", "dateBooking", "currentUser", "storeId"]),
  },

  created() {
    if (!Array.isArray(this.dataProcess) || this.dataProcess.length === 0) {
      this.$router.push({name: 'combo-list', query: {id: this.$route.query.id}})
    }
    this.groupTime = this.getGroupTime(CONSTANTS.START_TIME,CONSTANTS.END_TIME);
    this.getChooseDate(this.day)
    if (!this.dateBooking || !this.dateBooking[0] || !this.dateBooking[0].date || !this.dateBooking[0].time) {
      this.dateBook([{date: '', time: ''}]);
    } else {
      if (this.blockHour(this.dateBooking[0].time, this.listBlockHour)
        || !this.day || this.blockHour(this.dateBooking[0].time, this.listStoreSchedule)
        || this.blockHour(this.dateBooking[0].time, this.blockTime24h)) {
        this.dateBook([{date: this.dateBooking[0].date, time: ''}]);
      } else {
        this.day = this.dateBooking[0].date
        this.time = this.dateBooking[0].time
      }
    }
    this.getCurrentDateTime();
  },

  watch: {
    month: function () {
      this.getDaysInMonth();
    }
  },
  updated() {
    this.checkCurrentTime()
  },
  methods: {
    ...mapActions([DATE_BOOKING]),
    getCurrentDateTime() {
      this.startLoading();
      this.year = (this.day !== "" && this.day !== null) ? moment(this.day, 'yyyy-MM-DD').format('yyyy') : moment().add(1, 'days').format('YYYY');
      this.month = (this.day !== "" && this.day !== null) ? moment(this.day, 'yyyy-MM-DD').format('MM') : moment().add(1, 'days').format('MM');
      this.getChooseDate(this.day, true);
      this.getDaysInMonth();
      this.checkCurrentTime();
    },
    checkCurrentTime() {
      if(moment(this.day).isSame(moment().format('yyyy-MM-DD'))) {
        this.checkCurrentDate = true;
      } else {
        this.checkCurrentDate = false;
      }
    },
    getDayJapanese(day) {
      let name = '日'
      switch (day) {
        case 0:
          name = '日'
          break
        case 1:
          name = '月'
          break
        case 2:
          name = '火'
          break
        case 3:
          name = '水'
          break
        case 4:
          name = '木'
          break
        case 5:
          name = '金'
          break
        case 6:
          name = '土'
          break
        default:
          name = '日'
      }
      return name
    },
    getChooseDate(day, first = false) {
      if (!first) {
        this.time = ''
      }
      this.blockTime24h = [];
      if(moment(day, 'yyyy-MM-DD').isSame(moment().add('1', 'days').format('yyyy-MM-DD'))) {
        this.blockTime24h = [{
          star_hour: '00:00',
          end_hour: moment().format('HH:mm')
        }]
      }
      this.day = day ? day : moment().add('1', 'days').format('yyyy/MM/DD');
      this.getListBlockHour();
    },

    getDaysInMonth() {
      let date = moment(this.year + '-' + this.month + '-1', 'YYYY-MM-DD')
      let days = [];
      while (moment(date, 'YYYY-MM-DD').format('MM') === this.month) {
        if (moment(date, 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD'))) {
          days.push(moment(date, 'YYYY-MM-DD'));
        }
        date = moment(date, 'YYYY-MM-DD').add('1', "days")
      }
      this.days = days;
    },

    nextMonth() {
      let next = moment(this.year + '-' + this.month, 'YYYY-MM').add('1', 'months')
      this.year = next.format('YYYY')
      this.month = next.format('MM')
      this.day = ''
      this.time = ''
    },

    preMonth() {
      let next = moment(this.year + '-' + this.month, 'YYYY-MM').subtract('1', 'months')
      this.year = next.format('YYYY')
      this.month = next.format('MM')
      this.day = ''
      this.time = ''
    },

    getChooseTime(time) {
      this.time = time;
    },

    getListBlockHour() {
      this.startLoading()
      this.store_id = this.storeId;
      this.groupTime = this.groupTime.filter(time => {
        return time !== 1260
      })
      ApiService.get(`booking/block-hour?store_id=` + this.store_id + '&' + `date=` + this.day)
          .then(({data}) => {
            let arrayListBlockHours = []
            this.listStoreSchedule = []
            this.endLoading();
            if(data.data.blockHours.length > 0) {
              data.data.blockHours.forEach((value) => {
                value.forEach((val) => {
                  arrayListBlockHours.push(val);
                });
              });
            }
            this.listBlockHour = arrayListBlockHours;
            if(data.data.storeSchedule.length > 0) {
              data.data.storeSchedule.forEach((value) => {
                if (value.status === 0) {
                  this.listStoreSchedule = [{
                    star_hour: this.convertMinutesToHour(CONSTANTS.START_TIME),
                    end_hour: this.convertMinutesToHour(CONSTANTS.END_TIME)
                  }]
                } else {
                  this.listStoreSchedule = [
                    {
                      star_hour: '00:15',
                      end_hour: value.work_start_time
                    },
                    {
                      star_hour: value.work_end_time,
                      end_hour: '22:00',
                    },
                  ]
                  if (value.off_start_time && value.off_end_time) {
                    this.listStoreSchedule.push({
                      star_hour: value.off_start_time,
                      end_hour: value.off_end_time,
                    })
                  }
                }
              });
            }
          })
          .catch((e) => {
            this.endLoading();
            if (e.response.status !== 401) {
              this.notifyError(e.response.message)
            }
          })
    },

    nextStep() {
      this.dateBook([{
        'date' : this.day,
        'time' : this.time
      }]);
      if(this.time) {
        this.$router.push({ name: 'info-user', query: {id: this.$route.query.id} });
      } else {
        this.notifyError('必須項目に入力してください。')
      }
    } ,
  }

}
</script>

<style scoped>
.header-calenda {
  color: #191970;
  font-weight: bold;
}
.header-calenda a {
  color: #191970;
  margin-left: 20px;
  margin-right: 20px;
}
.month-days {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.month-days::-webkit-scrollbar {
   width: 10px;
 }
.month-days::-webkit-scrollbar:horizontal {
   height: 10px;
 }
.month-days::-webkit-scrollbar-track {
   background-color: transparentize(#ccc, 0.7);
 }
.month-days::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background: transparentize(#ccc, 0.5);
   box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
 }
.list-day {
  width: max-content;
}
.col-item-day {
  float: left;
  width: 43px;
  margin: 10px;
}
.button-day {
  width: 50px;
  padding: 12px 10px;
  font-size: 14px;
}
.button-common {
  color: #191970;
  font-weight: bolder;
  border: solid #191970 1px;
  background-color: #ffffff;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  border-radius: 4px;
}
.button-time {
  width: 80px;
  padding: 12px 10px;
  font-size: 14px;
  border-color: white;
  height: 42px;
}
.button-common:disabled {
  background-color: #B8B0A4;
  color: black;
  border: solid #B8B0A4 2px;
}
.active {
  background-color: #191970;
  color: white;
  border-color: #191970;
}
.text-item {
  padding: 5px 0px;
  margin-bottom: 0;
}
.col-time {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.time-margin {
  margin: 10px 5px;
}
</style>
