<template>
    <el-dialog
        title="Tips"
        :visible.sync="dialog"
        :width="width"
        :show-close="false"
        :close-on-click-modal="closeOnClickModal"
        center>
        <slot name="header" style="text-align: center"></slot>
        <slot name="content"></slot>
        <span slot="footer" class="dialog-footer">
                <el-button v-if="showCancelButton" @click="$emit('closeDialog')">{{btnCancel}}</el-button>
                <el-button class="btn_submit_dialog" @click="$emit('submit')">{{btnOk}}</el-button>
              </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        btnOk: {
            type: String,
            default: 'はい'
        },
        btnCancel: {
            type: String,
            default: 'キャンセル'
        },
        showCancelButton: {
            type: Boolean,
            default: true
        },
        closeOnClickModal: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: '70%'
        },
    }
};
</script>

<style scoped>
.btn_submit_dialog {
    font-family: kzk_bold;
    color: #fff;
    background: #FC9220;
}
</style>
