export const START_TIME = 600;
export const END_TIME = 1260;

export const MAX_SIZE_FILE = 10240;

export const USER_TYPE = {
    NEW_CUSTOMER: 0,
    REPEATER: 1,
};
export const CATEGORY = {
    CATEGORY_1: 1,
    CATEGORY_2: 2,
    CATEGORY_3: 3
};
