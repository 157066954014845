import Vue from 'vue'
import env from "../core/config/env";
import Router from 'vue-router'
import Login from "@/components/Login";
import Privacy from "@/components/pages/policy/PrivacyPolicy";
import Term from "@/components/pages/policy/TermofUse";
import DetailCampain from "@/components/pages/DetailCampain";
import RegisterEmail from "@/components/pages/RegisterEmail";
import SendWelcomeMsg from "@/components/pages/SendWelcomeMsg";
import Verify from "@/components/pages/Verify";
import Welcome from "@/components/pages/Welcome";
import ticket from "@/components/pages/ticket/List";
import payment from "@/components/pages/payment/Detail";
import comboList from "../components/stepBooking/stepBookingPage/ListComboService";
import profileCustomer from "../components/pages/profile/Index";
import FormReview from "../components/pages/review/Form";
import serviceCombo from "./serviceCombo";
import service from "./service";
import historyBooking from "@/components/pages/history-booking/List";
import infoUser from "../components/stepBooking/stepBookingPage/EnterUserInfo";
import confirmBooking from "../components/stepBooking/stepBookingPage/ConfimBooking";
import customerType from "@/components/pages/type-customer/Choose";
import contract from "@/components/pages/contract/index";
import CalendarForNewCustomer from "../components/stepBooking/stepBookingPage/NewCustomer";
import CalendarForRepeater from "../components/stepBooking/stepBookingPage/CalendarForRepeater";
import endBooking from "../components/stepBooking/stepBookingPage/endBooking";
import pdf from "@/components/pages/pdf/Index";

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    ...serviceCombo,
    ...service,
    {
      path: env.PREFIX_URL + '/login',
      name: 'login',
      meta: {
        notAuthenticated: true,
        title: `${env.DOMAIN_TITLE} | ログイン`
      },
      component: Login
    },
    {
      path: env.PREFIX_URL + '/privacy',
      name: 'privacy',
      meta: {
        notAuthenticated: true,
        title: `${env.DOMAIN_TITLE} | プライバシー`
      },
      component: Privacy
    },
    {
      path: env.PREFIX_URL + '/term',
      name: 'term',
      meta: {
        notAuthenticated: true,
        title: `${env.DOMAIN_TITLE} | 利用規約`
      },
      component: Term
    },
    {
      path: env.PREFIX_URL + '/campaign/detail/:campainId',
      name: 'campaign',
      component: DetailCampain,
      meta: {
        notAuthenticated: true,
        title: `${env.DOMAIN_TITLE} | キャンペーン`
      },
    },
    {
      path: env.PREFIX_URL + '/register-email',
      name: 'register',
      component: RegisterEmail,
      meta: {
        title: `${env.DOMAIN_TITLE} | 登録`
      },
    },
    {
      path: env.PREFIX_URL + '/verify-user',
      name: 'verify',
      meta: {
        notAuthenticated: true,
        title: `${env.DOMAIN_TITLE} | メール確認`
      },
      component: Verify
    },
    {
      path: env.PREFIX_URL + '/verified-welcome',
      name: 'welcome',
      component: Welcome,
      meta: {
        notAuthenticated: true,
        title: `${env.DOMAIN_TITLE} | ウエルカム`
      },
    },
    {
      path: env.PREFIX_URL + '/ticket',
      name: 'ticket',
      component: ticket,
      meta: {
        title: `${env.DOMAIN_TITLE} | チケット`
      },
    },
    {
      path: env.PREFIX_URL + '/payment/:paymentId',
      name: 'payment',
      component: payment,
      meta: {
        title: `${env.DOMAIN_TITLE} | 決済`
      }
    },
    {
      path: env.PREFIX_URL + '/combo/list',
      name: 'combo-list',
      meta: {
        notAuthenticated: true,
        title: `${env.DOMAIN_TITLE} | コース一覧`
      },
      component: comboList
    },
    {
      path: env.PREFIX_URL + '/profile',
      name: 'profile',
      meta: {
        title: `${env.DOMAIN_TITLE} | プロファイル`
      },
      component: profileCustomer
    },
    {
      path: env.PREFIX_URL + '/history-booking',
      name: 'history-booking',
      component: historyBooking,
      meta: {
        title: `${env.DOMAIN_TITLE} | 予約履歴`
      }
      },
    {
      path: env.PREFIX_URL + '/info-user',
      name: 'info-user',
      component: infoUser,
      meta: {
        title: `${env.DOMAIN_TITLE} | プロファイル`
      },
    },
    {
      path: env.PREFIX_URL + '/confirm-booking',
      name: 'confirm-booking',
      component: confirmBooking,
      meta: {
        title: `${env.DOMAIN_TITLE} | 予約確認`
      },
    },
    {
      path: env.PREFIX_URL + '/end-booking',
      name: 'end-booking',
      meta: {
        title: `${env.DOMAIN_TITLE} | 予約終了`
      },
      component: endBooking
    },
    {
      path: env.PREFIX_URL + '/choose-type-user',
      name: 'choose-type-user',
      component: customerType,
      meta: {
        title: `${env.DOMAIN_TITLE} | ユーザー`
      },
    },
    {
      path:  env.PREFIX_URL + '/calendar-for-new-customer',
      name: 'calendar-for-new-customer',
      meta: {
        title: `${env.DOMAIN_TITLE} | 新規`
      },
      component: CalendarForNewCustomer
    },
    {
      path:  env.PREFIX_URL + '/calendar-for-repeater',
      name: 'calendar-for-repeater',
      meta: {
        title: `${env.DOMAIN_TITLE} | リピーター`
      },
      component: CalendarForRepeater
    },
    {
      path:  env.PREFIX_URL + '/contract',
      name: 'contract',
      meta: {
        title: `${env.DOMAIN_TITLE} | 契約書`
      },
      component: contract
    },
    {
      path:  env.PREFIX_URL + '/send-welcome-msg',
      name: 'send-welcome-msg',
      meta: {
        title: `${env.DOMAIN_TITLE} | ウエルカム`
      },
      component: SendWelcomeMsg
    },
    {
      path:  env.PREFIX_URL + '/form-review',
      name: 'form-review',
      meta: {
        title: `${env.DOMAIN_TITLE} | レビュー`
      },
      component: FormReview
    },
    {
      path:  env.PREFIX_URL + '/pdf',
      name: 'pdf',
      meta: {
        title: `${env.DOMAIN_TITLE}`
      },
      component: pdf
    }
  ]
})
