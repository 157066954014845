<template>
  <div class="common-page">
    <!-- ======= Header ======= -->
    <header v-if="this.$router.history.current.name !== 'login'" id="header">
      <KTHeader></KTHeader>
    </header>
    <!-- End Header -->
    <main>
      <div class="sub-main">
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import KTHeader from "./header/Header";
export default {
	name: "Layout-ansena",
	components: {
		KTHeader
	}
};
</script>

<style>
#header {background: #ffffff;}
</style>
