<template>
  <div>
    <div v-if="!currentUser.contract" class="container login text-center">
      <p class="font-weight-bold">まだ契約書がありません。</p>
      <p class="font-weight-bold"> 管理者にご連絡してください。</p>
      <div class="justify-content-center d-flex">
        <Button customStyle="width:50%" @submit="backToLineApp" customClassName="border-none-and-background-aqua">LINEに戻る</Button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Button from "@/components/layout/components/ButtonCommon";

export default {
  name: "contract",
  components : {
    Button
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  created() {
    if (this.currentUser.contract) {
      window.location.href = this.currentUser.contract;
    }
  }
};
</script>

<style scoped>
.container-embed {
  width: 100%;
}
embed {
  width: 100%;
  height: 100vh;
}
.container {
  padding: 30px;
}
</style>
