<template>
  <div class="container login">
    <p class="font-weight-bold">{{currentUser.name}}　様</p>
    <p class="font-weight-bold">お友達登録ありがとうございます。</p>
    <p class="font-weight-bold">ぐり〜んりぷる公式アカウントです。</p>
    <p class="font-weight-bold">下記プロフィール登録をお願い致します。</p>
    <div class="justify-content-center d-flex">
      <Button customStyle="width:50%" @submit="$router.push({name: 'profile'})" customClassName="border-none-and-background-aqua">入力フォームへ</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/layout/components/ButtonCommon";
import {mapGetters} from "vuex";

export default {
  name: 'sendWelcomeMsg',
  components : {
    Button
  },
  computed:{
    ...mapGetters([
      'currentUser',
      'isAuthenticated'
    ]),
  },
}

</script>

<style scoped>
.container {
  padding: 30px;
}
</style>
