<template>
    <div class="notification">
        <el-dialog
            title="Tips"
            :visible.sync="dialog"
            width="30%"
            :before-close="close"
            :show-close="true">
            <div class="parent-notification">
              <div v-if="type === 'error'" class="check-container">
                <!-- <div class="check-background error">
                  <img src="../../../../public/img/error.png"/>
                </div>
                <div class="check-shadow error-shadow"></div> -->
              </div>
              <div v-if="type === 'success'" class="check-container">
                <div class="check-background">
                  <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <div class="check-shadow"></div>
              </div>
                <div class="content">{{content}}</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    computed: {
        ...mapState({
            dialog: state => state.loading.dialog,
            content: state => state.loading.content,
            btnOK: state => state.loading.btnOK,
            type: state => state.loading.type,
        }),
    },
    methods: {
      close() {
        this.$store.dispatch('openDialog', false)
      }
    }
};
</script>

<style scoped>
i {
  font-size: 30px;
}
.el-icon-error {
  color: red;
}
.parent-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.content {
  font-size: 20px;
  padding-bottom: 20px;
}
.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.dialog-footer {

}
</style>
