import ApiService from "@/core/services/api.service";

export const DATE_BOOKING  = "dateBook";
export const GET_SERVICE_LIST  = "getListService";
export const SERVICE_LIST  = "listServiceCombo";
export const SET_CHOOSE_PROCESS  = "setChooseProcess";
export const SET_ALL_CHOOSE_PROCESS  = "setAllChooseProcess";
export const REMOVE_CHOOSE_PROCESS  = "removeChooseProcess";
export const TYPE_CUSTOMER  = "typeCustomer";
export const SET_STORE_ID  = "setStoreId";

const state = {
  typeCustomer: '',
  date: '',
  serviceList: {},
  getChooseProcess: [],
  store_id: 1
};

const getters = {
  typeCustomerChoose(state) {
    return state.typeCustomer;
  },
  dateBooking(state) {
    return state.date;
  },
  listServiceCombo(state) {
    return state.serviceList;
  },
  dataProcess(state) {
    return state.getChooseProcess;
  },
  storeId(state) {
    return state.store_id;
  }
};

const actions = {
  [TYPE_CUSTOMER](context, typeCustomer) {
    context.commit(TYPE_CUSTOMER, typeCustomer);
  },
  [DATE_BOOKING](context, dataDateTime) {
    context.commit(DATE_BOOKING, dataDateTime);
  },
  [GET_SERVICE_LIST](context) {
    return new Promise((resolve) => {
      ApiService.get(`service/list`)
        .then(({ data }) => {
          context.commit(SERVICE_LIST, data.data);
          resolve(data);
        })
        .catch((e) => {
          return e;
        });
    });
  },

  [SET_CHOOSE_PROCESS](context, data) {
    context.commit(SET_CHOOSE_PROCESS, data);
  },

  [SET_ALL_CHOOSE_PROCESS](context, data) {
    context.commit(SET_ALL_CHOOSE_PROCESS, data);
  },

  [REMOVE_CHOOSE_PROCESS](context) {
    context.commit(REMOVE_CHOOSE_PROCESS);
  },

  [SET_STORE_ID](context, data) {
    context.commit(SET_STORE_ID, data);
  },
}

const mutations = {
  [TYPE_CUSTOMER](state, data) {
    state.typeCustomer = data
  },
  [DATE_BOOKING](state, dataDateTime) {
    state.date = dataDateTime;
  },
  [SERVICE_LIST](state, data) {
    state.serviceList = data
  },

  [SET_STORE_ID](state, data) {
    state.store_id = data
  },
  [SET_CHOOSE_PROCESS](state, data) {
    if (data) {
      state.getChooseProcess.push(data)
    }
  },
  [SET_ALL_CHOOSE_PROCESS](state, data) {
    if (data) {
      state.getChooseProcess = data
    }
  },
  [REMOVE_CHOOSE_PROCESS](state) {
    state.getChooseProcess = []
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
};
