import env from "../core/config/env";

const comboListRouter = [
    {
        path: env.PREFIX_URL + `/service-combo/:id`,
        name: "service-combo-detail",
        meta: {
            notAuthenticated: true,
            title: `${env.DOMAIN_TITLE} | サービス・コース詳細`
        },
        component: () => import("@/components/pages/service-combo/Detail"),
    }
];

export default comboListRouter
