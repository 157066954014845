<template xmlns="http://www.w3.org/1999/html">
  <div class="container login">
    <p class="font-weight-bold">ご予約申し込みありがとうございます。</p>
    <p class="font-weight-bold">まだ予約は確定していません。</p>
    <p class="font-weight-bold">予約が確定次第、改めて予約確定連絡を致します。</p>
    <p class="font-weight-bold">*3日以上経っても連絡がない場合は、お手数ですがお問い合わせいただきますようお願い致します。</p>
    <div class="justify-content-center d-flex">
      <Button customStyle="width:50%" @submit="backToLineApp" customClassName="border-none-and-background-aqua">LINEに戻る</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/layout/components/ButtonCommon";

export default {
  components : {
    Button
  },
}
</script>

<style scoped>
.container {
  padding: 30px;
}
</style>
